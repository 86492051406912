import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import React from 'react';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user } = useAuth();

  // If user is null, it means the check hasn't finished.
  // Display a loader or null to prevent unprotected rendering.
  if (user === null) {
    return null; // or a loader
  }

  if (!user) {
    // user is not authenticated
    return <Navigate to='/' />;
  }

  return children;
};

export default ProtectedRoute;

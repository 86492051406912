const config = {
  maintenance: process.env.MAINTENANCE || false,
  api: {
    loginPath: process.env.LOGIN_PATH || '/th/login/p?t=',
    validatePath: process.env.LOGIN_PATH || '/th/login/token/validate?t=',
    passwordlessLoginPath:
      process.env.PASSWORDLESS_LOGIN_PATH || '/th/login/passwordless/start?emailAddress=',
    registrationPath: process.env.REGISTRATION_PATH || '/th/account/register',
    profilePath: process.env.PROFILE_PATH || '/th/profile',
    configPath: process.env.CONFIG_PATH || '/th/config/site',
    categoriesPath: process.env.CATEGORIES_PATH || '/js/vacancy/categories',
    applicationsPath: process.env.CATEGORIES_PATH || '/th/profile/applications',
    tasksPath: process.env.CATEGORIES_PATH || '/th/profile/tasks',
  },
};

export default config;

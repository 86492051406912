import { Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiData } from '../../ApplicationsProvider';
import { useAuth } from '../../AuthProvider';
import NoApplications from '../../Icons/NoApplications';
import WavingHand from '../../Icons/WavingHand';
import JobCard from '../../SharedComponents/CardList/JobCard';
import LoadingSpinner from '../../SharedComponents/LoadingIcon/Index';
import PageContainer from '../../SharedComponents/PageContainer/Index';
import { daysAgo } from '../../helperFunctions';
import ErrorMessage from '../../SharedComponents/ErrorMessage/Index';

const ApplicationsPage: React.FC = () => {
  const { apiData, error, isLoading, loadData, isDataLoaded } = useApiData();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isDataLoaded) {
      loadData();
    }
  }, [isDataLoaded, apiData]);

  useEffect(() => {
    // No need to let them see the list of jobs if there is only one
    if (apiData && apiData.length === 1) {
      navigate(`/job/${apiData[0].vacancyId}`);
    }
  }, [apiData]);

  if (isLoading) {
    return (
      <PageContainer>
        <LoadingSpinner />
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <ErrorMessage error={error} />
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      <Box
        sx={{
          color: 'text.primary',
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            py: 5,
          },
        }}
      >
        {apiData && apiData.length > 1 ? (
          <>
            <Box
              sx={{
                color: 'text.primary',
                textAlign: 'center',
              }}
            >
              <Typography
                variant='h5'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  m: 'auto',
                  mb: 1,
                  justifyContent: 'center',
                  textTransform: 'capitalize',
                  gap: 1,
                }}
              >
                <WavingHand />
                Hi {user?.firstName}!
              </Typography>
              <Typography variant='h5'>You Have {apiData?.length} Open Applications</Typography>
              <Typography variant='subtitle1' sx={{ mt: 1 }}>
                See below for any actions you need to perform to progress your applications
              </Typography>
            </Box>
            <Grid
              item
              xs={12}
              lg={9}
              md={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
                mt: '2rem',
                mb: '2rem',
              }}
            >
              {apiData.map((job) => (
                <JobCard
                  key={job.applicationId}
                  id={job.vacancyId}
                  title={job.vacancyName}
                  description={job.description || 'No description'}
                  notificationCount={job.numTasks}
                  date={daysAgo(job.appliedDateTime)}
                  image={job.image}
                  isHired={job.isHired}
                  hasMoreInfoUrl={!!(job.stageUrl && job.stageUrl?.length > 0)}
                />
              ))}
            </Grid>
          </>
        ) : (
          <Box sx={{ p: 10 }}>
            <NoApplications />
            <Typography sx={{ fontSize: '1.3rem', fontWeight: '500', mt: 2 }}>
              You Have No Open Applications!
            </Typography>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
};

export default ApplicationsPage;

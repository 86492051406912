import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import { useConfig } from './ConfigProvider';
import { GlobalStyles } from '@mui/styled-engine';

const globalStyles = (
  <GlobalStyles
    styles={{
      '.MuiBackdrop-root': { backdropFilter: 'blur(2px)', backgroundColor: 'rgba(87,100,110,.33)' },
    }}
  />
);

const CustomThemeProvider: React.FC = () => {
  const config = useConfig();
  // fallback to default theme
  const theme = createTheme(config.theme?.muiTheme?.data);

  return theme ? (
    <ThemeProvider theme={theme}>
      {globalStyles}
      <App />
    </ThemeProvider>
  ) : null;
};

export default CustomThemeProvider;

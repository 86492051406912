import { Box, useTheme, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useConfig } from '../../../ConfigProvider';

const SuccessMessage = () => {
  const theme = useTheme();
  const config = useConfig();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        width: '-webkit-fill-available',
        marginLeft: '3rem',
        marginRight: '3rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          marginLeft: '5px',
          marginRight: '5px',
        },
      }}
    >
      <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
        {config.tenantName}
      </Typography>
      <Box sx={{ margin: 'auto', mt: '1rem' }}>
        <img src={'/icon.svg'} alt='logo' />
      </Box>
      <Typography
        variant='h5'
        sx={{
          fontWeight: '400',
          textAlign: 'center',
          color: '#2D2D2D',
        }}
      >
        Congratulations
      </Typography>
      <Link to='/applications'>Go to your Applications</Link>
    </Box>
  );
};

export default SuccessMessage;

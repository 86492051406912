import { AppBar, Toolbar, IconButton, Badge, Box, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import InitialledCircle from '../../../SharedComponents/InitialledCircle/Index';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/system';
import { useAuth } from '../../../AuthProvider';
import { useConfig } from '../../../ConfigProvider';
import { useNavigate } from 'react-router-dom';

interface StyledColourEdgeProps {
  primaryColour: string;
  secondaryColour: string;
}
const StyledColourEdge = styled('div')`
  width: 100%;
  height: 0.5rem;
  ${({ primaryColour, secondaryColour }: StyledColourEdgeProps) => `
    background-image: linear-gradient(to right, ${primaryColour}, ${secondaryColour});
  `}
`;

interface HeaderProps {
  jobTitle: any;
}

const Header = ({ jobTitle }: HeaderProps) => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const config = useConfig();

  const { signOut, user } = useAuth();

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleLogoutClick = () => {
    handleMobileMenuClose();
    signOut();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  return (
    <AppBar position='fixed' sx={{ backgroundColor: 'white' }}>
      <Box>
        <Toolbar
          sx={{ height: '100%', display: 'flex', justifyContent: 'space-between', px: '1rem' }}
          disableGutters
        >
          <IconButton sx={{ padding: 0 }} onClick={() => navigate('/applications')}>
            <svg
              width='32'
              height='32'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15 19L8 12L15 5'
                stroke='#0A74D8'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </IconButton>
          <Box
            sx={{
              justifyContent: 'center',
              color: 'black',
              fontSize: '1.5rem',
              maxWidth: 'calc(100% - 5rem)',
            }}
          >
            <Typography
              variant='h6'
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {jobTitle}
            </Typography>
          </Box>
          <IconButton color='inherit' sx={{ padding: 0 }} onClick={handleMenuClick}>
            <Badge color='primary'>
              <InitialledCircle
                firstInitial={user?.firstName && user?.firstName[0]}
                secondInitial={user?.surname && user?.surname[0]}
              />
            </Badge>
          </IconButton>
        </Toolbar>
        <StyledColourEdge
          primaryColour={config?.theme?.muiTheme?.data?.palette?.primary?.main}
          secondaryColour={config?.theme?.muiTheme?.data?.palette?.secondary?.main}
        />
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id='mobile-menu'
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem onClick={handleLogoutClick}>
            <IconButton color='inherit'>
              <Badge color='secondary'>
                <LogoutIcon />
              </Badge>
            </IconButton>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </AppBar>
  );
};

export default Header;

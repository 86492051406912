import React from 'react';
import { MuiTelInput } from 'mui-tel-input';

// Interface for form errors
interface FormErrors {
  contactNumber?: string;
  // Add more error fields if needed for other form inputs
}

interface MyComponentProps {
  formValues: {
    contactNumber: string;
    // Add more form fields if needed
  };
  formErrors: FormErrors;
  handleInputChange: (value: string) => void;
}

const MyComponent: React.FC<MyComponentProps> = ({ formValues, formErrors, handleInputChange }) => {
  return (
    <MuiTelInput
      defaultCountry='GB'
      preferredCountries={['GB']}
      value={formValues.contactNumber}
      forceCallingCode
      onChange={handleInputChange}
      label='Contact Number'
      error={Boolean(formErrors.contactNumber)}
      helperText={formErrors.contactNumber}
    />
  );
};

export default MyComponent;

import { Box, Container, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { useConfig } from '../../ConfigProvider';
import { useAuth } from '../../AuthProvider';

interface CentreContainerProps {
  children: ReactNode;
  hasLogo?: boolean;
  containerWidth?: 'sm' | 'md' | 'lg' | 'xl';
}

interface Background {
  backgroundImage: string;
  backgroundSize?: string;
  backgroundRepeat?: string;
  backgroundPosition?: string;
}

const CentreContainer: React.FC<CentreContainerProps> = ({ children, hasLogo, containerWidth }) => {
  const theme = useTheme();
  const config = useConfig();
  const auth = useAuth();

  let background: Background = {
    backgroundImage: 'linear-gradient(rgba(225, 225, 226, 0.4), rgba(225, 225, 226,1))',
  };

  if (config?.theme?.background) {
    background = {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(${config.theme.background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    };
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        padding: '2rem',
        py: auth.user ? '8rem' : 0,
        ...background,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          padding: '0',
          backgroundImage: 'none',
          justifyContent: 'flex-start',
        },
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          backgroundColor: 'white',
          padding: '0 !important',
          borderRadius: '0.5rem',
          [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            px: 0,
          },
        }}
        maxWidth={containerWidth}
      >
        {hasLogo ? (
          <Box
            sx={{
              border: '1px solid lightgrey',
              borderRadius: '10%',
              backgroundColor: 'white',
              width: '10rem',
              height: '10rem',
              display: 'flex',
              justifyContent: 'center',
              padding: '1rem',
              boxSizing: 'border-box',
              mb: '1rem',
              mt: '2.5rem',
              [theme.breakpoints.down('xl')]: {
                width: '7rem',
                height: '7rem',
              },
              [theme.breakpoints.down('md')]: {
                width: '6rem',
                height: '6rem',
                mt: '0',
              },
            }}
          >
            <img
              src={config?.theme?.logo}
              alt='Logo'
              style={{ width: '100%', height: 'auto', objectFit: 'contain', padding: '1rem' }}
            />
          </Box>
        ) : null}
        {children}
      </Container>
    </Box>
  );
};

export default CentreContainer;

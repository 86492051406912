import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/Homepage/HomePage';
import Applications from './Pages/Applications/Applications';
import JobPage from './Pages/Job/JobPage';
import SettingsPage from './Pages/Profile/ProfilePage';
import RegistrationPage from './Pages/Registration/RegistrationPage';
import { AuthProvider } from './AuthProvider';
import { ApiDataProvider } from './ApplicationsProvider';
import TokenLoadingPage from './Pages/TokenLoadingPage';
import ValidateLoadingPage from './Pages/ValidateLoadingPage';
import ForbiddenPage from './Pages/ForbiddenPage';

import ProtectedRoute from './ProtectedRoute';

export default function App() {
  return (
    <Router>
      <ApiDataProvider>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/login/p' element={<TokenLoadingPage />} />
            <Route path='/action' element={<ValidateLoadingPage />} />
            <Route path='/registration' element={<RegistrationPage />} />
            <Route
              path='/applications'
              element={
                <ProtectedRoute>
                  <Applications />
                </ProtectedRoute>
              }
            />
            <Route
              path='/settings'
              element={
                <ProtectedRoute>
                  <SettingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/job/:id/:action?'
              element={
                <ProtectedRoute>
                  <JobPage />
                </ProtectedRoute>
              }
            />
            <Route path='*' element={<ForbiddenPage />} />
          </Routes>
        </AuthProvider>
      </ApiDataProvider>
    </Router>
  );
}

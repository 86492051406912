import React from 'react';
import { Typography, Box, useTheme, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import CentreContainer from '../SharedComponents/CentreContainer/ExternalPage';

const ForbiddenPage: React.FC = () => {
  const theme = useTheme();

  return (
    <CentreContainer hasLogo>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          m: '2rem',
          width: '-webkit-fill-available',
          marginLeft: '3rem',
          marginRight: '3rem',
          textAlign: 'center',
          [theme.breakpoints.down('sm')]: {
            marginLeft: '5px',
            marginRight: '5px',
          },
        }}
      >
        <Typography
          variant='h1'
          sx={{
            fontWeight: '400',
            textAlign: 'center',
            color: '#2D2D2D',
            pb: 3,
          }}
        >
          404
        </Typography>
        <Typography
          variant='h5'
          sx={{
            fontWeight: '400',
            textAlign: 'center',
            color: '#2D2D2D',
            pb: 2,
          }}
        >
          Something went <b>WRONG!</b>
        </Typography>
        <Link to='/'>
          <Button variant='contained'>Back to Hompage </Button>
        </Link>
      </Box>
    </CentreContainer>
  );
};

export default ForbiddenPage;

import { SVGProps } from 'react';
import { JSX } from 'react/jsx-runtime';

const SVGComponent = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='32' height='32' rx='16' fill='#80E084' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.5967 9.85497C22.8839 10.0464 22.9615 10.4345 22.77 10.7217L15.27 21.9717C15.1662 22.1275 14.9979 22.2285 14.8116 22.247C14.6253 22.2654 14.4405 22.1994 14.3081 22.0669L9.30806 17.0669C9.06398 16.8229 9.06398 16.4271 9.30806 16.1831C9.55214 15.939 9.94786 15.939 10.1919 16.1831L14.6529 20.644L21.73 10.0283C21.9214 9.74111 22.3095 9.6635 22.5967 9.85497Z'
      fill='#1E8522'
    />
  </svg>
);

export default SVGComponent;

import React from 'react';
import { Box, Button, Typography } from '@mui/material';

type ViewJobButtonProps = {
  onButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
  isHired: boolean;
};

const ViewJobButton: React.FC<ViewJobButtonProps> = ({ onButtonClick, isHired }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 1,
        backgroundColor: '#7180DE',
        borderRadius: '5px',
        boxShadow: 0,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {isHired ? (
          <>
            <img src={'/confetti.svg'} height={42} alt='React Logo' />
            <Typography
              variant='body2'
              sx={{ fontWeight: 'bold', margin: 'auto', pl: 1.5, color: 'white' }}
            >
              Hired!
            </Typography>
          </>
        ) : (
          <Typography
            variant='body2'
            sx={{ fontWeight: 'bold', margin: 'auto', pl: 1.5, color: 'white' }}
          >
            Information Available
          </Typography>
        )}
      </Box>
      <Button
        variant='contained'
        onClick={onButtonClick}
        sx={{
          textTransform: 'none', // To keep the button text as is
          borderRadius: 1, // Rounded corners for the button
          marginLeft: 1, // Space between badge and button
          backgroundColor: '#0A74D8', // Replace with your actual color
          color: 'white', // Replace with your actual color
          fontWeight: 'bold', // To make the text bold
          boxShadow: 0, // To remove the default box shadow
          fontSize: '1.1rem', // To change the font size
        }}
      >
        View Info
      </Button>
    </Box>
  );
};

export default ViewJobButton;

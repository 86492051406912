import {
  Box,
  List,
  ListItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import React, { useState } from 'react';
import ErrorIcon from '../../../Icons/ErrorIcon';
import SuccessIcon from '../../../Icons/SuccessIcon';
import WarningIcon from '../../../Icons/WarningIcon';
import { formatDate } from '../../../helperFunctions';
import { JobAction } from '../JobPageTypes';
import ListAction from './ListAction';
import CustomActionItem from './CustomListAction';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const StyledFilterButton = styled(ToggleButton)(() => ({
  border: 'none !important',
  margin: '1rem',
  color: '#292929 !important',
  borderRadius: '25px !important',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  lineHeight: 'normal',
  '&.MuiToggleButton-standard': {
    backgroundColor: '#C9C9C9',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: alpha('#C9C9C9', 0.4),
      color: 'gray !important',
    },
  },
  '&.Mui-error': {
    backgroundColor: '#FFACA1', // customize this color
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: alpha('#FFACA1', 0.4),
      color: 'gray !important',
    },
  },
  '&.MuiToggleButton-warning': {
    backgroundColor: '#FFDE8B', // customize this color
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: alpha('#FFDE8B', 0.4),
      color: 'gray !important',
    },
  },
}));

// Define the styles for the custom list item
const CustomListItem = styled(ListItem)(() => ({
  borderRadius: 8,
  marginBottom: '1.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: 'none',
  padding: '1rem',
  position: 'relative',

  '&::after': {
    content: '" "',
    position: 'absolute',
    bottom: '-45px',
    borderLeft: '2px solid green',
    height: 100,
    left: 31,
    zIndex: -1,
  },

  '&.todo::after': {
    content: '" "',
    position: 'absolute',
    bottom: '-45px',
    borderLeft: '2px dotted #FFDE8B',
    height: 100,
    left: 31,
    zIndex: -1,
  },

  '&.urgent::after': {
    content: '" "',
    position: 'absolute',
    bottom: '-45px',
    borderLeft: '2px dotted #FFACA1',
    height: 100,
    left: 31,
    zIndex: -1,
  },
}));

const AppliedListItem = styled(CustomListItem)(() => ({
  marginBottom: '1.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: 'none',
  padding: '1rem',
  position: 'relative',
  backgroundColor: 'none',

  '&::after': {
    content: null,
    position: 'absolute',
    bottom: '-20px',
    borderLeft: '2px solid green',
    height: 0,
    left: 32,
    zIndex: -1,
  },
}));

type StateIconType = {
  [key: string]: React.ReactElement;
};
const stateIcon: StateIconType = {
  urgent: <ErrorIcon />,
  todo: <WarningIcon />,
  done: <SuccessIcon />,
};

interface JobActionsProps {
  actionItems: JobAction[];
  handleOpenModal: (iframeSrc: string, title: string, id: string, isModal?: boolean) => void;
  customStageAction?: {
    url?: string;
    message?: string;
  };
  jobId?: string;
}

const JobActions = ({
  actionItems,
  handleOpenModal,
  customStageAction,
  jobId,
}: JobActionsProps) => {
  const [filter, setFilter] = useState<string[] | null>([]);

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, newFilter: string[] | null) => {
    setFilter(newFilter);
  };

  return (
    <>
      <Typography variant='h6' component='div' sx={{ textAlign: 'center', pb: 2 }}>
        There are{' '}
        <b>
          {
            actionItems.filter((a) => {
              return a.state === 'todo' || a.state === 'urgent';
            }).length
          }
        </b>{' '}
        things you need to do:
      </Typography>
      <StyledToggleButtonGroup color='primary' value={filter} onChange={handleFilterChange}>
        <StyledFilterButton color='error' value='urgent'>
          Urgent: {actionItems.filter((a) => a.state === 'urgent').length}
        </StyledFilterButton>
        <StyledFilterButton color='warning' value='todo'>
          To Do: {actionItems.filter((a) => a.state === 'todo').length}
        </StyledFilterButton>
        <StyledFilterButton color='standard' value='done'>
          Done: {actionItems.filter((a) => a.state === 'done').length}
        </StyledFilterButton>
      </StyledToggleButtonGroup>
      <List sx={{ position: 'relative', zIndex: 2 }}>
        {customStageAction && customStageAction.url ? (
          <CustomActionItem
            url={customStageAction.url}
            handleOpenModal={handleOpenModal}
            message={customStageAction.message}
            jobId={jobId}
          />
        ) : null}
        {actionItems
          .filter((a) => {
            return !filter?.includes(a.state);
          })
          .map(({ title, state, iframeSrc, completedDate, id, message }) => (
            <CustomListItem
              sx={{
                backgroundColor:
                  state === 'urgent' ? '#FFACA1' : state === 'todo' ? '#FFDE8B' : '#F4F5F7',
              }}
              className={state}
              key={id}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {stateIcon[state]}

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <span>{title}</span>
                  {state !== 'done' && (
                    <span style={{ fontSize: '0.8rem', color: '#616161' }}>
                      Requested: {completedDate && formatDate(completedDate)}
                    </span>
                  )}
                </Box>
              </Box>
              <ListAction
                id={id}
                title={title}
                state={state}
                iframeSrc={iframeSrc}
                completedDate={completedDate}
                handleOpenModal={handleOpenModal}
                message={message}
              />
            </CustomListItem>
          ))}
        <AppliedListItem>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <SuccessIcon />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <span>You Applied!</span>
              <span style={{ fontSize: '0.8rem', color: '#616161' }}>Today</span>
            </Box>
          </Box>
        </AppliedListItem>
      </List>
    </>
  );
};

export default JobActions;

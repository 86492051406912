import React from 'react';
import { Box, Typography } from '@mui/material';

interface ErrorMessageProps {
  error: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  return (
    <Box sx={{ margin: 'auto', textAlign: 'center' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='100'
        height='100'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
        <line x1='12' y1='8' x2='12' y2='12'></line>
        <line x1='12' y1='16' x2='12' y2='16'></line>
      </svg>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Oops! Something went wrong.
      </Typography>
      <Typography sx={{ mb: 3 }}>
        {error || 'An error occurred while fetching data. Please try again later.'}
      </Typography>
    </Box>
  );
};

export default ErrorMessage;

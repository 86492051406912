import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useAuth } from '.././AuthProvider';
import config from '../config/config';
import LoadingSpinner from '../SharedComponents/LoadingIcon/Index';
import { useConfig } from '../ConfigProvider';
const {
  api: { validatePath },
  url,
} = config;

const ValidateLoadingPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get('t');
  const navigate = useNavigate();
  const config = useConfig();
  const { login } = useAuth();

  useEffect(() => {
    let isMounted = true;
    const cancelSource = axios.CancelToken.source();

    if (tokenParam) {
      axios
        .get(`${url}${validatePath}${tokenParam}`, {
          headers: {
            Tenant: config.tenantId,
          },
          cancelToken: cancelSource.token,
        })
        .then((response) => {
          const data = response.data;
          Cookies.set('authToken', data.token, { expires: 1 / 24 });
          login();
          if (data.vacancyId) {
            navigate(`/job/${data.vacancyId}/${data.action}`);
          }
        })
        .catch((err: AxiosError) => {
          const error = err as any;
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            if (isMounted) {
              if (!error.response) {
                navigate('/');
                toast.error('A network error occurred. Please try again later.', {
                  toastId: 'networkError',
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              } else {
                switch (error.response.status) {
                  case 404:
                    navigate('/registration');
                    break;
                  case 410:
                    navigate('/');
                    toast.error('Url has expired, please request a new one!', {
                      toastId: 'expiredLink',
                      position: toast.POSITION.BOTTOM_CENTER,
                    });
                    break;
                  case 401:
                    navigate('/');
                    toast.error(
                      "The link you've followed is no longer valid. Please login again to view your applications.",
                      {
                        toastId: 'wrongLink',
                        position: toast.POSITION.BOTTOM_CENTER,
                      },
                    );
                    break;
                  case 423:
                    navigate('/');
                    toast.error('Rate limited. Please try again later.', {
                      toastId: 'rateLimitError',
                      position: toast.POSITION.BOTTOM_CENTER,
                    });
                    break;
                  case 503:
                    navigate('/');
                    toast.error('The service is temporarily unavailable. Please try again later.', {
                      toastId: 'serviceUnavailable',
                      position: toast.POSITION.BOTTOM_CENTER,
                    });
                    break;
                  default:
                    navigate('/');
                    toast.error('An error occurred. Please try again.', {
                      toastId: 'unknownError',
                      position: toast.POSITION.BOTTOM_CENTER,
                    });
                    break;
                }
              }
            }
          }
        })
        .finally(() => {
          if (isMounted) setLoading(false);
        });
    }
    return () => {
      isMounted = false;
      cancelSource.cancel('Operation canceled by the user.');
    };
  }, [tokenParam, navigate]);

  return <Box>{loading ? <LoadingSpinner /> : null}</Box>;
};

export default ValidateLoadingPage;

import React, { createContext, useContext, useState } from 'react';
import axios, { AxiosError } from 'axios';
import config from './config/config';
import { useConfig } from './ConfigProvider';
import Cookies from 'js-cookie';
import { errorMessageFinder } from './helperFunctions';

const {
  api: { applicationsPath },
  url,
} = config;

interface JobFromApi {
  accountName: string;
  appliedDateTime: string;
  candidateId: string;
  numTasks: number;
  vacancyId: string;
  vacancyName: string;
  hired: boolean;
  supInfoUrl?: string;
  supInfoMessage?: string;
  supInfoTitle?: string;
  jobInformation: {
    salary: string;
    hours: string;
  };
}

interface StatusToStateMapping {
  [key: string]: string; // This index signature says: For any string key, the value will be a string.
}

const hoursTypeMap: StatusToStateMapping = {
  FULL_TIME: 'Full-time',
  PART_TIME: 'Part-time',
  CASUAL: 'Casual',
};

function mapApplicationToJob(
  application: JobFromApi,
  allVacancies: any[],
  image: string,
): JobInterface {
  // Find the matching vacancy in allVacancies
  const vacancyInfo = allVacancies.find((vacancy) => vacancy.id === application.vacancyId);

  return {
    applicationId: application.candidateId,
    vacancyName: application.vacancyName,
    vacancyId: application.vacancyId,
    image: image,
    description: vacancyInfo?.positionProfile?.description,
    location: vacancyInfo?.positionProfile?.location,
    hours: hoursTypeMap[vacancyInfo?.positionProfile?.hoursType],
    renumeration: vacancyInfo?.positionProfile?.remuneration?.description,
    numTasks: application.numTasks,
    appliedDateTime: application.appliedDateTime,
    isHired: application.hired,
    stageUrl: application.supInfoUrl,
    stageMessage: application.supInfoMessage,
    stageTitle: application.supInfoTitle,
    jobInformation: application.jobInformation,
  };
}

const ApiDataContext = createContext<
  | {
      apiData: JobInterface[];
      isLoading: boolean;
      isDataLoaded: boolean;
      error?: string;
      loadData: () => Promise<void>;
    }
  | undefined
>(undefined);

export const useApiData = () => {
  const context = useContext(ApiDataContext);
  if (!context) {
    throw new Error('useApiData must be used within a ApiDataProvider');
  }
  return context;
};

type ApiDataProviderProps = {
  children: React.ReactNode;
};

export const ApiDataProvider: React.FC<ApiDataProviderProps> = ({ children }) => {
  const [apiData, setApiData] = useState<JobInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const config = useConfig();

  const loadData = async () => {
    if (!isLoading) {
      setIsLoading(true);
      setError('');

      try {
        const token = Cookies.get('authToken');
        const applicationsResponse = await axios.get(`${url}${applicationsPath}`, {
          headers: { Authorization: `Bearer ${token}`, Tenant: config.tenantId },
        });

        if (!applicationsResponse.data || applicationsResponse.data.length === 0) {
          setError('No data returned from API');
          return;
        }
        // Fetch all vacancies in parallel
        const vacanciesPromises = applicationsResponse.data.map((application: any) =>
          axios
            .get(`${url}/js/vacancy/${application.vacancyId}`, {
              headers: {
                Tenant: config.tenantId,
                'Content-Type': 'application/json; charset=utf-8',
              },
            })
            .then((response) => ({ ...response.data, vacancyId: application.vacancyId }))
            .catch((error) => ({ error, vacancyId: application.vacancyId })),
        );

        const vacanciesResponses = await Promise.all(vacanciesPromises);

        // Assuming the response structure for each vacancy fetch is suitable for direct mapping
        // Filter out any failed fetches before mapping
        const successfulVacancies = vacanciesResponses.filter((vacancy) => !vacancy.error);

        // Map each application to JobInterface, including the vacancy info
        const mappedJobs = applicationsResponse.data
          .map((application: any) =>
            mapApplicationToJob(application, successfulVacancies, config.theme.background),
          )
          .sort(
            (a: any, b: any) =>
              new Date(b.appliedDateTime).getTime() - new Date(a.appliedDateTime).getTime(),
          );

        setIsDataLoaded(true);
        setApiData(mappedJobs);
      } catch (error) {
        setError(errorMessageFinder(error as AxiosError));
        setIsDataLoaded(true);
        setApiData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ApiDataContext.Provider value={{ apiData, isLoading, isDataLoaded, error, loadData }}>
      {children}
    </ApiDataContext.Provider>
  );
};

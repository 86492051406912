import React from 'react';
import { Container, Box, useTheme } from '@mui/material';
import Header from '../Header/Index';
import Footer from '../Footer/Index';
import CentreContainer from '../CentreContainer';

type PageContainerProps = {
  children: React.ReactNode;
  CustomHeader?: React.ReactNode;
  containerWidth?: 'sm' | 'md' | 'lg' | 'xl';
};

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  CustomHeader,
  containerWidth,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ minHeight: '100vh', background: 'white' }}>
      {CustomHeader ? CustomHeader : <Header />}
      <CentreContainer containerWidth={containerWidth || 'md'}>
        <Container
          maxWidth='xl'
          sx={{
            mt: 0,
            height: '100%',
            minHeight: '30vh',
            p: '2.5rem !important',
            [theme.breakpoints.down('md')]: {
              p: '1rem !important',
              pt: '3rem !important',
            },
          }}
        >
          {children}
        </Container>
      </CentreContainer>
      <Footer />
    </Box>
  );
};

export default PageContainer;

import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import TaskButton from './TaskButton';
import ViewJobButton from './ViewJobButton';
import { useConfig } from '../../ConfigProvider';

const CardHover = styled(Card)(() => ({
  transition: '0.3s',
  boxShadow: '0px 5px 4px rgba(0, 0, 0, 0.2)',
  border: 'none',
  borderRadius: '8px', // slightly increased roundness
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '4rem',
  width: '6rem',
  [theme.breakpoints.down('sm')]: {
    height: '4rem',
    width: '7rem',
  },
  borderRadius: '0.5rem',
  overflow: 'hidden',
  background: theme.palette.background.default,
  padding: '0.5rem',
}));

const LogoImage = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  borderRadius: '10%',
});

const GradientBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(to top, white, transparent)',
}));

type ListingCardProps = {
  id: string;
  title: string;
  description: string | TrustedHTML;
  date: string;
  image?: string;
  notificationCount?: number;
  isHired?: boolean;
  hasMoreInfoUrl?: boolean;
};

const JobCard: React.FC<ListingCardProps> = ({
  id,
  title,
  date,
  image,
  notificationCount,
  isHired,
  hasMoreInfoUrl,
}) => {
  const navigate = useNavigate();
  const config = useConfig();
  let cardContent = (
    <Box sx={{ display: 'flex' }}>
      <Typography sx={{ color: '#1E8522', fontSize: '1.2rem' }}>Processing </Typography>
      <Typography sx={{ lineHeight: '2rem', ml: 3 }}>No Action Required</Typography>
    </Box>
  );

  if (notificationCount && notificationCount > 0) {
    cardContent = (
      <TaskButton
        notificationCount={notificationCount}
        onButtonClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          // Replace with your navigation logic, such as using useNavigate from react-router-dom
          navigate(`/job/${id}`);
          return false;
        }}
      />
    );
  }

  if (isHired || hasMoreInfoUrl) {
    cardContent = (
      <ViewJobButton
        isHired={!!isHired}
        onButtonClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          // Replace with your navigation logic, such as using useNavigate from react-router-dom
          navigate(`/job/${id}`);
          return false;
        }}
      />
    );
  }

  return (
    <CardHover
      variant='outlined'
      sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} // changed layout to column
      onClick={() => navigate(`/job/${id}`)}
    >
      <Box sx={{ position: 'relative', lineHeight: 0 }}>
        <CardMedia
          component='img'
          sx={{
            width: '100%', // make image full width
            height: '6rem', // adjust height as needed
            objectFit: 'cover', // ensures the image covers the area
          }}
          image={image || '/background-image.jpg'}
          alt={title}
        />
        <GradientBox />
      </Box>
      <CardContent
        sx={{
          flexGrow: 1, // allow content to fill space
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2 }}>
            <Typography
              gutterBottom
              variant='h6'
              component='span'
              sx={{
                textAlign: 'left',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant='body2'
              component='span'
              color='text.secondary'
              sx={{
                textAlign: 'left',
              }}
            >
              Applied: {date}
            </Typography>
          </Box>
          <LogoBox>
            <LogoImage src={config.theme.logo} alt='Logo' />
          </LogoBox>
        </Box>
        <Box>{cardContent}</Box>
      </CardContent>
    </CardHover>
  );
};

export default JobCard;

import { Work } from '@mui/icons-material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { Box, Typography, styled, Grid, Paper, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';

const ConfettiImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

interface JobDetailsProps {
  label?: string;
  value?: string;
  Icon?: React.ElementType;
}

const InfoCard = ({ label, value, Icon }: JobDetailsProps) => (
  <Paper
    elevation={2}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant='subtitle1' color='textSecondary' gutterBottom>
        {label}
      </Typography>
      <Typography variant='body1'>{value}</Typography>
    </Box>
    {Icon && <Icon color='action' />}
  </Paper>
);

const WelcomeMessage = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LoaderContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  background: 'rgba(255, 255, 255, 0.8)',
}));

const WelcomeImage = styled('img')(({ theme }) => ({
  height: '100%',
  maxHeight: '15rem',
  paddingBottom: '2rem',
  width: '100%',
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

interface WelcomeProps {
  jobTitle?: string;
  firstName?: string;
  description?: string;
  imageUrl?: string;
  lastName?: string;
  jobLocation?: string;
  iframeUrl?: string;
  renumeration?: string;
}

const Welcome = ({
  jobTitle,
  firstName,
  description,
  imageUrl,
  lastName,
  jobLocation,
  iframeUrl,
  renumeration,
}: WelcomeProps) => {
  const [iframeHeight, setIframeHeight] = useState(100); // Default height
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResizeMessage = (event: any) => {
      if (event.data && event.data.height) {
        setIframeHeight(event.data.height);
        setTimeout(() => setIsLoading(false), 1000); // Hide loader after 1 second
      }
    };

    window.addEventListener('message', handleResizeMessage);

    return () => {
      window.removeEventListener('message', handleResizeMessage);
    };
  }, []);

  return (
    <Box>
      {imageUrl ? (
        <WelcomeMessage>
          <WelcomeImage src={imageUrl} alt='welcome Image' />
        </WelcomeMessage>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            flex: '1 1 0px',
            textAlign: 'center',
          }}
        >
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <ConfettiImage src={'/confetti.svg'} height={30} alt='React Logo' />
            <Typography variant='h5' sx={{ fontWeight: '500' }}>
              Welcome to the team, {firstName}!
            </Typography>
          </Box>
          <Typography variant='body1'>{description}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InfoCard
              label='Name'
              value={`${firstName} ${lastName}`}
              Icon={PersonOutlineOutlinedIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard
              label='Contracted Hours'
              value='37.5 Hours Per Week'
              Icon={DescriptionOutlinedIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard label='Role' value={jobTitle} Icon={Work} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard label='Salary' value={renumeration} Icon={LocalAtmOutlinedIcon} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard label='Place of work' value={jobLocation} Icon={BusinessOutlinedIcon} />
          </Grid>
        </Grid>
        <Box sx={{ position: 'relative', mt: '2rem' }}>
          {isLoading && (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          )}
          <iframe
            id='dynamicHeightIframe'
            src={iframeUrl}
            title='Dynamic Height Iframe'
            style={{
              width: '99%',
              height: `${iframeHeight}px`,
              border: 'none',
              overflow: 'hidden',
            }}
            scrolling='no'
            onLoad={() => setIsLoading(false)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;

import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useApiData } from '../../ApplicationsProvider';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { errorMessageFinder } from '../../helperFunctions';
import config from '../../config/config';
import { useConfig } from '../../ConfigProvider';
import LoadingSpinner from '../../SharedComponents/LoadingIcon/Index';
import PageContainer from '../../SharedComponents/PageContainer/Index';
import IframeModal from './Components/IframeModal';
import Header from './Components/Header';
import JobActions from './Components/JobActions';
import JobDetails from './Components/JobDetails';
import PageChanger from './Components/PageChanger';
import ErrorMessage from '../../SharedComponents/ErrorMessage/Index';
import { PageType, JobAction, TaskInterface, TaskAPIInterface } from './JobPageTypes';
import useConfetti from './Components/useConfetti';
import Welcome from './Components/Welcome';
import PrivacyLinks from './Components/PrivacyLinks';
import { useAuth } from '../../AuthProvider';

interface ActionMap {
  [key: string]: string;
}

const actionMap: ActionMap = {
  RTW: 'Right to Work',
  ONBOARDING: 'Your Onboarding information',
  REFERENCE: 'Your Reference information',
  FLEXI_FORM: 'Flexi Form',
  PERSONALITY_TEST: 'Personality Test',
  EVENT: 'Interview',
  EDOC: 'E-Doc',
};

const {
  api: { tasksPath },
  url,
} = config;

function updateUrlWithCid(urlString: string, cidValue: string): string {
  // Parse the URL
  const url = new URL(urlString);
  const params = url.searchParams;

  // Check if the 'cid' parameter is present
  if (!params.has('cid')) {
    // If not present, add 'cid' with the provided value
    params.append('cid', cidValue);

    // Return the updated URL as a string
    return url.toString();
  }

  // Return the original URL if 'cid' is already present
  return urlString;
}

interface StatusToStateMapping {
  [key: string]: string;
}
const modalUrls = ['booking-dot-e4s-development', 'talent-funnel'];

const statusToState: StatusToStateMapping = {
  REMINDER_1_SENT: 'urgent',
  REMINDER_2_SENT: 'urgent',
  REQUESTED: 'todo',
  PART_RECEIEVED: 'todo',
  ADDITIONAL_INFO_REQUESTED: 'todo',
  INVITED: 'todo',
  COMPLETED: 'done',
  RECEIVED: 'done',
  ACCEPTED: 'done',
};

function getStatus(status: string): string {
  return statusToState[status] || 'todo'; // Default to 'urgent' if status is not found
}

function mapJobTaskList(
  curr: TaskInterface[],
  task: TaskAPIInterface,
  applicationId: string,
): TaskInterface[] {
  if (task.taskType === 'FLEXI_FORM' || task.taskType === 'EDOC') {
    const items: TaskInterface[] = [];

    task.subTasks?.forEach((form) => {
      const state = getStatus(task.currentStatus.status);
      let title = `${form.name}`;
      let url = form.url;

      if (task.taskType === 'EDOC') {
        title = `Document: ${form.name}`;
        url = updateUrlWithCid(form.url, applicationId);
      }

      items.push({
        id: task.sourceTaskId + form.name,
        title,
        completed: state === 'done',
        completedDate: form.createdDateTime,
        actionType: task.taskType,
        iframeSrc: url,
        state: state,
      });
    });
    curr.push(...items);
  } else {
    const state = getStatus(task.currentStatus.status);
    let iframeSrc = task.url;
    let title = actionMap[task.taskType];
    let message = undefined;

    if (task.taskType === 'REFERENCE' && task.currentStatus.status === 'REQUESTED') {
      iframeSrc = '';
      title = 'Awaiting referees to complete references';
    }

    if (task.taskType === 'REFERENCE' && task.currentStatus.status === 'PART_RECEIVED') {
      message = '1 out of 2 References Received';
    }

    curr.push({
      id: task.sourceTaskId,
      title,
      completed: state === 'done',
      completedDate: task.currentStatus.statusDateTime,
      actionType: task.taskType,
      iframeSrc,
      state: state,
      message,
    });
  }

  return curr;
}

const JobPage: React.FC = () => {
  const { id, action } = useParams();
  const [job, setJob] = useState<JobInterface | null>(null);
  const [actionItems, setActionItems] = useState<JobAction[]>([]);
  const [iframeSrc, setIframeSrc] = useState('');
  const [iframeTitle, setIframeTitle] = useState('');
  const [iframeFormId, setIframeFormId] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [pageType, setPageType] = React.useState<PageType>('ACTIONS');
  const navigate = useNavigate();
  const [isLoadingTasks, setIsLoadingTasks] = useState<boolean>(false);
  const [tasksError, setTasksError] = useState<string>('');
  const { confetti } = useConfetti();
  const config = useConfig();
  const { user } = useAuth();

  const handlePageType = (newAlignment: PageType) => {
    setPageType(newAlignment);
  };

  const { apiData, isLoading, loadData, isDataLoaded, error } = useApiData();

  const loadJobData = async (jobId?: string, candidate?: string) => {
    if (jobId && candidate) {
      setIsLoadingTasks(true);
      setTasksError('');
      try {
        const token = Cookies.get('authToken');
        const response = await axios.get(`${url}${tasksPath}/${candidate}`, {
          headers: { Authorization: `Bearer ${token}`, Tenant: config.tenantId },
        });

        if (!response.data) {
          setTasksError('No data returned from API');
        } else {
          const mappedTasks: TaskInterface[] = response.data.reduce(
            (a: TaskInterface[], b: TaskAPIInterface) => mapJobTaskList(a, b, candidate),
            [],
          );
          setActionItems(
            mappedTasks.sort(
              (a, b) => new Date(b.completedDate).getTime() - new Date(a.completedDate).getTime(),
            ),
          );
          if (action) {
            const actionItem = mappedTasks.find((item) => item.actionType === action);
            if (actionItem) {
              handleOpenModal(actionItem.iframeSrc);
            }
          }
        }
      } catch (error) {
        setTasksError(errorMessageFinder(error as AxiosError));
        setActionItems([]);
      } finally {
        setIsLoadingTasks(false);
      }
    }
  };

  useEffect(() => {
    if (id && isDataLoaded && apiData.length > 0 && !isLoadingTasks && !isLoading) {
      const currentJob = apiData.find((job) => job.vacancyId === id);
      if (currentJob) {
        setJob(currentJob);
        setPageType(currentJob.isHired ? 'SPLASH' : 'ACTIONS');
        loadJobData(id, currentJob.applicationId);
      } else {
        setTasksError('Failed to find Job.');
      }
    }
  }, [id, isDataLoaded, apiData]);

  useEffect(() => {
    if (!isDataLoaded) {
      loadData();
    }
  }, [isDataLoaded, apiData]);

  const [searchParams, setSearchParams] = useSearchParams();
  const openModal = searchParams.get('modal') === 'open';
  const handleOpenModal = (src?: string, title?: string, formId?: string, useModal?: boolean) => {
    if (!src) return;

    if (modalUrls.some((url) => src.includes(url) || useModal)) {
      setIsModalLoading(true);
      setIframeSrc(src);
      setIframeFormId(formId || '');
      setIframeTitle(title || 'Please complete the form below');
      setSearchParams({ modal: 'open' });
    } else {
      window.open(src, '_blank');
    }
  };

  const handleCloseModal = (iframeId?: string) => {
    setSearchParams({}, { replace: true });
    if (iframeId) {
      confetti();
      setActionItems((prevState) => {
        return prevState.map((item) => {
          if (item.id === iframeId) {
            item.state = 'done';
          }
          return item;
        });
      });
    }
  };

  if (isLoading) {
    return (
      <PageContainer CustomHeader={isMobile ? <Header jobTitle={job?.vacancyName} /> : undefined}>
        <LoadingSpinner />
      </PageContainer>
    );
  }

  return (
    <PageContainer CustomHeader={isMobile ? <Header jobTitle={job?.vacancyName} /> : undefined}>
      <Box
        sx={{
          position: 'relative',
          [theme.breakpoints.down('md')]: {
            pt: '3rem',
            pb: '3rem',
          },
        }}
      >
        {isMobile || apiData?.length === 1 ? null : (
          <Box
            sx={{
              position: 'absolute',
              top: '-5rem',
              left: '-2.5rem',
            }}
          >
            <IconButton
              sx={{ padding: 0, color: 'white !important', fontSize: '1rem', gap: '0.5rem' }}
              onClick={() => navigate('/applications')}
            >
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M17 10C17 10.4142 16.6642 10.75 16.25 10.75L5.61208 10.75L9.76983 14.7094C10.0684 14.9965 10.0777 15.4713 9.79062 15.7698C9.50353 16.0684 9.02875 16.0777 8.73017 15.7906L3.23017 10.5406C3.08311 10.3992 3 10.204 3 10C3 9.79599 3.08311 9.60078 3.23017 9.45938L8.73017 4.20938C9.02875 3.92228 9.50353 3.93159 9.79062 4.23017C10.0777 4.52875 10.0684 5.00353 9.76983 5.29063L5.61208 9.25L16.25 9.25C16.6642 9.25 17 9.58579 17 10Z'
                  fill='white'
                />
              </svg>

              <div style={{ fontWeight: 'bold' }}>All Applications</div>
            </IconButton>
          </Box>
        )}
        {error ? (
          <ErrorMessage error={error} />
        ) : (
          <>
            <PageChanger
              setPage={handlePageType}
              page={pageType}
              showHiredState={job?.isHired || false}
            />
            {pageType === 'ACTIONS' || pageType === 'DETAILS' ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  pb: 2,
                  minHeight: '4rem',
                  [theme.breakpoints.down('md')]: {
                    minHeight: '7rem',
                  },
                }}
              >
                <Typography
                  variant='h4'
                  component='div'
                  sx={{
                    textAlign: 'center',
                    pb: 2,
                    [theme.breakpoints.down('md')]: {
                      pt: '4rem',
                    },
                  }}
                >
                  {job?.vacancyName}
                </Typography>
              </Box>
            ) : null}
            {pageType === 'DETAILS' ? (
              <JobDetails
                id={job?.vacancyId}
                title={job?.vacancyName}
                description={job?.description}
                image={job?.image}
                location={job?.location}
                renumeration={job?.renumeration}
                hours={job?.hours}
              />
            ) : pageType === 'SPLASH' ? (
              <Welcome
                jobTitle={job?.vacancyName}
                firstName={user?.firstName}
                lastName={user?.surname}
                description={job?.stageMessage}
                imageUrl={config.welcomeMediaUrl}
                jobLocation={job?.location?.city}
                iframeUrl={job?.stageUrl}
                renumeration={job?.renumeration}
              />
            ) : (
              <>
                {tasksError ? (
                  <ErrorMessage error={tasksError} />
                ) : (
                  <>
                    {isLoadingTasks ? (
                      <LoadingSpinner />
                    ) : (
                      <JobActions
                        actionItems={actionItems}
                        handleOpenModal={handleOpenModal}
                        customStageAction={{
                          url: job?.stageUrl,
                          message: job?.stageTitle,
                        }}
                        jobId={job?.vacancyId}
                      />
                    )}
                  </>
                )}
              </>
            )}
            <PrivacyLinks />
          </>
        )}
      </Box>
      <IframeModal
        isMobile={isMobile}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        id={iframeFormId}
        isLoading={isModalLoading}
        iframeSrc={iframeSrc}
        setIsLoading={setIsModalLoading}
        title={iframeTitle}
      />
    </PageContainer>
  );
};

export default JobPage;

import React from 'react';
import { Typography, Box, Grid, Paper } from '@mui/material';

interface JobDetailsProps {
  id?: string;
  title?: string;
  description?: string | TrustedHTML;
  image?: string;
  renumeration?: string;
  hours?: string;
  location?: {
    country: string;
    city: string;
  };
}

const JobDetails = ({ id, description, location, renumeration, hours }: JobDetailsProps) => {
  const rows = [
    { label: 'Hours:', value: hours },
    { label: 'Salary:', value: renumeration },
    { label: 'Ref :', value: id },
    { label: 'City:', value: location?.city },
  ];

  return (
    <Paper elevation={0}>
      {rows.map((row, index) => (
        <Grid
          container
          key={index}
          sx={{ backgroundColor: index % 2 === 0 ? 'grey.100' : 'grey.50' }}
        >
          <Grid item xs={6} sx={{ padding: 1, lineBreak: 'anywhere' }}>
            <Typography variant='subtitle1' color='text.secondary' gutterBottom>
              {row.label}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ padding: 1, lineBreak: 'anywhere' }}>
            <Typography variant='body1' gutterBottom>
              {row.value}
            </Typography>
          </Grid>
        </Grid>
      ))}
      {description && (
        <Box sx={{ paddingY: 2 }}>
          <Typography variant='subtitle1' color='text.secondary' gutterBottom>
            Description:
          </Typography>
          <Typography variant='body2' color='text.primary' gutterBottom>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default JobDetails;

import { styled } from '@mui/system';

interface strokeCol {
  [key: string]: string;
}
const strokeCol: strokeCol = {
  A: 'rgba(244,139,148,0.63)',
  B: '#e2bb78',
  C: 'rgba(80,126,101,0.68)',
  D: 'rgba(108,91,123,0.82)',
  E: '#355c7d',
  F: '#f7a8a6',
  G: 'rgba(253,202,88,0.87)',
  H: '#addcc9',
  I: '#bf6c85',
  J: '#60698a',
  K: '#f8b196',
  L: '#ffd17c',
  M: '#cbe7df',
  N: '#b2bec3',
  O: '#7fc7db',
  P: '#fdd2b5',
  Q: '#fff4e8',
  R: '#dbebc2',
  S: '#e8e7e5',
  T: '#84e2ea',
  U: '#d4f0f0',
  V: '#8fcaca',
  W: '#ffd8be',
  X: '#a2e1db',
  Y: '#f6eac2',
  Z: '#abdee6',
};

// Define TypeScript interfaces for props
interface CircleProps {
  firstInitial: string; // Notice the corrected spelling of 'firstInitial'
}

interface InitialledCircleProps extends CircleProps {
  secondInitial: string;
}

// Update the styled-component to use TypeScript interfaces
const Circle = styled('div')<CircleProps>`
  height: 30px;
  width: 30px;
  min-width: 30px;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: auto;
  margin-top: 2px;
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: ${({ firstInitial }: CircleProps) => strokeCol[firstInitial]};
`;

// Update the component to use TypeScript interfaces
function InitialledCircle({ firstInitial, secondInitial }: InitialledCircleProps) {
  return (
    <Circle
      firstInitial={firstInitial.toUpperCase()}
    >{`${firstInitial.toUpperCase()}${secondInitial.toUpperCase()}`}</Circle>
  );
}

// Default props using TypeScript
InitialledCircle.defaultProps = {
  firstInitial: 'N',
  secondInitial: 'A',
};

export default InitialledCircle;

import { useRef, useEffect } from 'react';
import JSConfetti from 'js-confetti';

function useConfetti() {
  const jsConfetti = useRef(null);

  function confetti() {
    const commonOptions = {
      confettiRadius: 5,
      confettiNumber: 100,
    };

    jsConfetti.current.addConfetti({
      ...commonOptions,
      confettiColors: ['#ff3838', '#ff9d00', '#fffb00', '#48ff00', '#00ffd5', '#0090ff', '#7e00ff'],
    });
  }

  useEffect(() => {
    jsConfetti.current = new JSConfetti();
  }, []);

  return { confetti };
}

export default useConfetti;

import React, { useEffect, useRef } from 'react';
import {
  IconButton,
  Slide,
  Typography,
  Box,
  Grow,
  AppBar,
  Toolbar,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import MaterialDialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import { useConfig } from '../../../ConfigProvider';
import { useState } from 'react';

// Define the spin animation using keyframes
const spin =
  '@keyframes spin { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }';

const LoaderImage = styled('img')({
  width: '50px',
  animation: `${spin} 1s linear infinite`,
});

const MobileTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const DesktopTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow in ref={ref} {...props} />;
});

interface IframeModalProps {
  isMobile: boolean;
  openModal: boolean;
  handleCloseModal: (id?: string) => void;
  isLoading: boolean;
  iframeSrc: string;
  setIsLoading: (isLoading: boolean) => void;
  title: string;
  id: string;
}

const IframeModal = ({
  isMobile,
  openModal,
  handleCloseModal,
  isLoading,
  iframeSrc,
  setIsLoading,
  title,
  id,
}: IframeModalProps) => {
  const config = useConfig();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleBackdropClick = () => {
    // Show confirmation dialog
    setShowConfirmationDialog(true);
  };

  const confirmClose = () => {
    // Close confirmation dialog and the main dialog
    setShowConfirmationDialog(false);
    handleCloseModal();
  };

  const cancelClose = () => {
    // Just close the confirmation dialog, keep the main dialog open
    setShowConfirmationDialog(false);
  };

  const handleIframeClose = (event: MessageEvent, id: string) => {
    const { data } = event;
    if (data === 'doClose') {
      if (iframeRef?.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage('beforeUnloadCalled', '*');
      }

      handleCloseModal();
    } else if (
      data === 'applicationComplete' ||
      data === 'bookingComplete' ||
      data === 'bookingCancelled'
    ) {
      handleCloseModal(id);
    }
  };

  useEffect(() => {
    window.addEventListener('message', (ev) => handleIframeClose(ev, id));
    return () => window.removeEventListener('message', (ev) => handleIframeClose(ev, id));
  }, [id]);

  return (
    <>
      <MaterialDialog
        fullScreen={isMobile}
        open={openModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            handleBackdropClick();
          } else {
            handleCloseModal();
          }
        }}
        TransitionComponent={isMobile ? MobileTransition : DesktopTransition}
        PaperProps={{
          style: isMobile
            ? {}
            : {
                height: '80vh',
                width: '80vw',
                maxWidth: '800px',
                overflowX: 'hidden',
                position: 'relative', // Set position relative to position loader absolutely inside
              },
        }}
      >
        <AppBar position='static'>
          <Toolbar>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <IconButton
              edge='end'
              color='inherit'
              onClick={() => handleCloseModal()}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ position: 'relative', height: 'calc(100% - 64px)', overflow: 'auto' }}>
          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                transition: 'opacity 0.5s ease-out', // Fade effect for the loader
                zIndex: 2,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <iframe
            src={iframeSrc}
            title={title}
            ref={iframeRef}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
            onLoad={() => setIsLoading(false)} // Hide loading spinner when iframe is loaded
          />
        </Box>
      </MaterialDialog>
      <MaterialDialog open={showConfirmationDialog} onClose={cancelClose}>
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ margin: 2, color: '#5a5a5a' }}>
            Closing this window may result in the loss of all of the entered information. Are you
            sure you want to close it?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: '#fff',
              border: '1px solid #c6cbcf',
              color: '#414141',
              fontSize: '0.8rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#5a5a5a',
                border: '1px solid #c6cbcf',
                color: 'white',
              },
            }}
            onClick={cancelClose}
          >
            No
          </Button>
          <Button
            sx={{
              backgroundColor: '#5a5a5a',
              border: '1px solid #c6cbcf',
              color: 'white',
              fontSize: '0.8rem',
              textTransform: 'none',
              '&:hover': {
                color: '#414141',
                backgroundColor: 'white',
              },
            }}
            onClick={confirmClose}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </MaterialDialog>
    </>
  );
};

export default IframeModal;

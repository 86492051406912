import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Button, TextField, Box, useTheme, Typography, Link, styled } from '@mui/material';
import MagicIcon from '@mui/icons-material/AutoFixHigh';
import { useState } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CentreContainer from '../../SharedComponents/CentreContainer/ExternalPage';
import { validateEmail, handleLoginError } from '../../helperFunctions';
import SuccessMessage from './Components/SuccessMessage';
import DividerWithText from './Components/DividerWithText';
import config from '../../config/config';
import { useConfig } from '../../ConfigProvider';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';

const {
  api: { passwordlessLoginPath },
  url,
} = config;

// Styled component for the container
const TypographyBox = styled(Typography)`
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  color: rgba(110, 110, 110);
  font-size: 0.8rem;
`;

// Styled component for the links
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledSeparator = styled(Typography)`
  margin: 0 0.5rem; // Adjust based on your layout
  color: #999; // Example color, adjust as needed
  font-size: 1rem;
`;

function ConfigLinks({
  privacyPolicyURL,
  termsOfUseURL,
}: {
  privacyPolicyURL: string;
  termsOfUseURL: string;
}) {
  return (
    <TypographyBox>
      {privacyPolicyURL && (
        <>
          <StyledLink href={privacyPolicyURL} target='_blank' rel='noopener noreferrer'>
            Privacy Policy
          </StyledLink>
          {termsOfUseURL && <StyledSeparator>•</StyledSeparator>}
        </>
      )}
      {termsOfUseURL && (
        <StyledLink href={termsOfUseURL} target='_blank' rel='noopener noreferrer'>
          Terms of Use
        </StyledLink>
      )}
    </TypographyBox>
  );
}

const LoginPage = () => {
  const [email, setEmail] = useState<string>('');
  const config = useConfig();
  const navigate = useNavigate();
  const { setUser, user } = useAuth();

  const theme = useTheme();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleLogin = async () => {
    if (!validateEmail(email)) {
      setHasError(true);
    } else {
      axios
        .get(`${url}${passwordlessLoginPath}${email}`, {
          headers: {
            Tenant: config.tenantId,
          },
        })
        .then(() => {
          setIsSuccess(true);
        })
        .catch((e) => {
          if (e?.response?.status === 404) {
            setUser({ email, firstName: '', surname: '' });
            navigate('/registration');
          }
          handleLoginError(e);
        });
    }
  };

  useEffect(() => {
    const token = Cookies.get('authToken');
    if (user && user.email && token) {
      navigate(`/applications`);
    }
  }, [user]);

  return (
    <CentreContainer hasLogo>
      {isSuccess ? (
        <SuccessMessage
          emailAddress={email}
          loginAgain={() => {
            setIsSuccess(false);
          }}
        />
      ) : (
        <>
          <Typography
            variant='h4'
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              color: '#2D2D2D',
              marginBottom: '1rem',
              mx: '3rem',
              [theme.breakpoints.down('xl')]: {
                fontSize: '1.7rem',
              },
            }}
          >
            {config?.welcomeMessage || `Welcome to ${config.tenantName}`}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              width: '-webkit-fill-available',
              marginLeft: '3rem',
              marginRight: '3rem',
              [theme.breakpoints.down('sm')]: {
                marginLeft: '2rem',
                marginRight: '2rem',
              },
            }}
          >
            <TextField
              label='Email'
              variant='filled'
              error={hasError}
              id='email'
              inputProps={{
                autoCapitalize: 'none',
              }}
              helperText={hasError ? 'Please enter a valid email' : null}
              sx={{
                width: '100%',
                marginBottom: '1rem',
                backgroundColor: 'white',
                borderRadius: '5px',
                margin: 'auto',
              }}
              value={email}
              onChange={(e) => {
                setHasError(false);
                setEmail(e.target.value.trim().toLowerCase());
              }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            <Button
              variant='contained'
              color='primary'
              startIcon={<MagicIcon />}
              onClick={handleLogin}
              sx={{
                width: '100%',
                height: '3rem',
                marginBottom: '1rem',
                margin: 'auto',

                borderRadius: '5px',
              }}
              disabled={!email}
            >
              Login with Magic Link
            </Button>
            <DividerWithText />
            <ConfigLinks
              privacyPolicyURL={config?.privacyPolicyURL}
              termsOfUseURL={config?.termsOfUseURL}
            />
          </Box>
        </>
      )}
      <ToastContainer />
    </CentreContainer>
  );
};

export default LoginPage;

// SettingsSection.tsx

import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import React, { useState } from 'react';
import JobCatagorySelect from '../../../SharedComponents/JobCategoriesSelect/Index';
import LocationSelect from '../../../SharedComponents/LocationSelect/Index';
import config from '../../../config/config';
import { Field } from '../ProfileTypes';

const {
  api: { profilePath },
  url,
} = config;

interface SettingsSectionProps {
  title: string;
  fields: Field[];
  displayValue: string | string[];
  onEdit: (title: string, editing: boolean) => void;
  disabled: boolean;
  onSave: (title: string, newSettings: any) => void;
}

const ProfileSection: React.FC<SettingsSectionProps> = ({
  title,
  fields,
  displayValue,
  onEdit,
  disabled,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState(fields);

  const handleEdit = () => {
    setIsEditing(true);
    onEdit(title, true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    onEdit(title, false);
    setValues(fields); // reset values
  };

  const handleSave = async () => {
    setIsEditing(false);
    onEdit(title, false);
    onSave(title, values);
  };

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = [...values];
    // newValues[index] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setValues(newValues);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: 2,
        pl: 0,
        m: 0,
        borderBottom: '1px solid lightgrey',
      }}
    >
      <Grid item xs={4} sm={4} sx={{ pl: '0 !important' }}>
        <Typography sx={{ fontSize: '1.1rem' }}>{title}</Typography>
      </Grid>

      <Grid
        item
        xs={5}
        sm={6}
        sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', pb: 1 }}
      >
        {!isEditing &&
          (typeof displayValue == 'string' ? (
            <Typography variant='body1'>{displayValue}</Typography>
          ) : (
            displayValue.map((value, index) => (
              <Typography key={index} variant='body1'>
                {value}
              </Typography>
            ))
          ))}

        {isEditing &&
          fields.map((field, index) => {
            return field.type === 'checkbox' ? (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={!!values[index]}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                }
                label={field.label}
              />
            ) : field.type === 'jobCategoriesSelect' ? (
              <JobCatagorySelect
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  const newValues = [...values];

                  // newValues[index] = typeof value === 'string' ? [value] : value;
                  // setValues(newValues);
                }}
                value={values[index] as any}
              />
            ) : field.type === 'location' ? (
              <LocationSelect
                onChange={(event) => {
                  const newValues = [...values];
                  newValues[index] = event?.target?.value || null;
                  setValues(newValues);
                }}
                initialValue={values[index] as any}
              />
            ) : (
              <TextField
                key={index}
                type={field.type}
                value={values[index]}
                label={field.label}
                onChange={(e) => handleInputChange(index, e as React.ChangeEvent<HTMLInputElement>)}
                fullWidth
              />
            );
          })}
      </Grid>

      <Grid item xs={3} sm={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {!isEditing && (
            <Button startIcon={<EditIcon />} onClick={handleEdit} disabled={disabled}>
              Edit
            </Button>
          )}
          {isEditing && (
            <>
              <Button color='secondary' variant='text' onClick={handleCancel}>
                Cancel
              </Button>
              <Button color='primary' variant='contained' onClick={handleSave}>
                Save
              </Button>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProfileSection;

import { SVGProps } from 'react';
import { JSX } from 'react/jsx-runtime';

const SVGComponent = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width='80'
    height='79'
    viewBox='0 0 80 79'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_470_7716)'>
      <path
        d='M30.6434 38.5635C29.2144 36.5986 28.3808 34.1658 28.3808 31.5288C28.3808 25.0555 33.425 19.8071 39.8813 19.7391H40.0004H40.1195C46.5758 19.8071 51.62 25.0555 51.62 31.5288C51.62 34.1658 50.7864 36.5986 49.3573 38.5635M30.6434 38.5635C32.719 41.4217 36.0534 43.2845 39.8813 43.3271H40.0004H40.1195C43.9473 43.2845 47.2818 41.4217 49.3573 38.5635M30.6434 38.5635C27.0368 37.4917 23.2429 39.916 20.8186 41.7534C17.7819 43.5057 17.4246 37.3812 15.7659 32.9154M40.0089 76.8504H33.7142L25.5141 65.4945M25.5141 65.4945L16.5995 53.1434M25.5141 65.4945C25.9565 62.3047 26.2116 58.3237 25.2589 55.976C24.3573 53.1008 23.5407 50.5404 22.7581 50.9657C20.2487 52.2502 18.2242 52.9222 16.5995 53.1434M16.5995 53.1434C10.4835 53.96 9.96458 48.2522 9.7179 43.5993C9.62433 41.9235 9.56479 40.3839 9.29258 39.3376C8.11021 36.7602 6.00064 36.9984 4.26535 36.0542C3.5253 35.6544 2.86181 35.0419 2.36845 33.9106C2.24936 33.6469 2.1728 33.3832 2.14728 33.1195C2.13027 33.0515 2.13027 32.9834 2.13027 32.9154C1.96014 29.5724 8.3739 26.9694 12.7717 28.8919C14.1752 29.5043 15.0854 31.0695 15.7659 32.9154M28.3808 32.9154H15.7659M49.3573 38.5635C52.964 37.4917 56.7578 39.916 59.1821 41.7534C62.2189 43.5057 62.5761 37.3812 64.2349 32.9154M63.4012 53.1434L54.4866 65.4945M63.4012 53.1434C61.7765 52.9222 59.752 52.2502 57.2427 50.9657C56.4601 50.5404 55.6435 53.1008 54.7418 55.976C53.7891 58.3237 54.0443 62.3047 54.4866 65.4945M63.4012 53.1434C69.5173 53.96 70.0362 48.2522 70.2829 43.5993C70.3764 41.9235 70.436 40.3839 70.7082 39.3376C71.8905 36.7602 74.0001 36.9984 75.7354 36.0542C76.4754 35.6544 77.1389 35.0419 77.6323 33.9106C77.7514 33.6469 77.828 33.3832 77.8535 33.1195C77.8705 33.0515 77.8705 32.9834 77.8705 32.9154C78.0406 29.5724 71.6268 26.9694 67.2291 28.8919C65.8255 29.5043 64.9154 31.0695 64.2349 32.9154M54.4866 65.4945L46.2865 76.8504H40.128H39.9919M64.2349 32.9154H51.62'
        stroke='#818181'
        strokeWidth='3'
      />
      <path
        d='M40.1965 1.08411L40.1648 10.3482M19.7969 7.71452L23.9127 12.9495M60.2045 7.853L55.8333 13.498'
        stroke='#818181'
        strokeWidth='2.495'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_470_7716'>
        <rect width='80' height='78.9541' fill='white' transform='translate(0 0.0229492)' />
      </clipPath>
    </defs>
  </svg>
);

export default SVGComponent;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useRef, useEffect, useMemo } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  styled,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { Box } from '@mui/system';

function countryCodeToFlagEmoji(countryCode: string): string {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

interface Country {
  code: string;
  name: string;
}

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 44,
  height: '100%',
  padding: theme.spacing(0, 2),
  color: 'rgba(0, 0, 0, 1)',
}));
// Expanding the country list
const countryList: Country[] = [
  { code: 'GB', name: 'United Kingdom' },
  { code: 'US', name: 'United States' },
  { code: 'AU', name: 'Australia' },
  { code: 'CA', name: 'Canada' },
  { code: 'DE', name: 'Germany' },
  { code: 'FR', name: 'France' },
  { code: 'IN', name: 'India' },
  { code: 'JP', name: 'Japan' },
  { code: 'BR', name: 'Brazil' },
  { code: 'ZA', name: 'South Africa' },
  // Add more countries as needed
];

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyC7gmtx-FKTvbTWPnBJxBOwudT_yOZ69Xs';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}
const placesService = {
  current: {
    getDetails: (b: any, c: any) => {
      return null;
    },
  },
};

interface InitialValue {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  county: string;
  postCode: string;
  country: string;
  formattedAddress: string;
  geoLocation: [number, number];
}

interface GoogleMapsProps {
  onChange: (value: any | null) => void;
  initialValue?: InitialValue | null;
  helperText?: string;
  error?: boolean;
}

const formatPlaceType = (value: any) => {
  return {
    description: value?.formattedAddress,
    structured_formatting: {
      main_text: value.city,
      secondary_text: value.county,
      main_text_matched_substrings: [],
    },
  };
};

export default function GoogleMaps({ onChange, initialValue, helperText, error }: GoogleMapsProps) {
  const [address, setAddress] = useState<InitialValue | null | undefined>(initialValue);
  const [manualEntry, setManualEntry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('GB');
  const [inputValue, setInputValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [countryPickerOpen, setCountryPickerOpen] = useState(false);
  const loaded = useRef(false);

  const handleCountryChange = (country: string) => {
    setSelectedCountry(country);
  };

  const toggleCountryPicker = () => {
    setCountryPickerOpen(!countryPickerOpen);
  };

  const toggleManualEntry = () => {
    setManualEntry(!manualEntry);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedAddress = { ...address, [name]: value };
    setAddress(updatedAddress as InitialValue);
    onChange(updatedAddress);
  };
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(
          { ...request, componentRestrictions: { country: selectedCountry } },
          callback,
        );
      }, 600),
    [selectedCountry],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService(
        null,
        {
          types: ['(regions)'],
          componentRestrictions: { country: selectedCountry }, // 2-letters code
        },
      );
      placesService.current = new (window as any).google.maps.places.PlacesService(
        document.createElement('div'),
      );
    }
    if (!autocompleteService.current || inputValue === '' || inputValue.length < 8) {
      setIsSearching(false);
      setOptions(address ? [formatPlaceType(address)] : []);
      return undefined;
    }

    setIsSearching(true);

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        setIsSearching(false);

        let newOptions: readonly PlaceType[] = [];

        if (address) {
          newOptions = [formatPlaceType(address)];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [address, inputValue, fetch]);

  const handlePlaceSelect = (newValue: any) => {
    if (newValue === null) {
      setOptions([]);
      setAddress(null);
      onChange(null); // If you want to communicate the cleared state to the parent.
      return;
    }

    placesService.current.getDetails({ placeId: newValue.place_id }, (place: any, status: any) => {
      if (status === 'OK') {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        let addressLine1 = '';
        let addressLine2 = '';
        let addressLine3 = '';
        let city = '';
        let county = '';
        let postCode = '';
        let country = '';

        place.address_components.forEach((component: any) => {
          if (component.types.includes('street_number')) {
            addressLine1 = component.long_name;
          } else if (component.types.includes('route')) {
            addressLine2 = component.long_name;
          } else if (component.types.includes('sublocality_level_1')) {
            addressLine3 = component.long_name;
          } else if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_2')) {
            county = component.long_name;
          } else if (component.types.includes('postal_code')) {
            postCode = component.long_name;
          } else if (component.types.includes('country')) {
            country = component.long_name;
          }
        });

        const formattedAddress = place.formatted_address || '';

        setAddress({
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          county,
          postCode,
          country,
          formattedAddress,
          geoLocation: [latitude, longitude],
        });
        onChange({
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          county,
          postCode,
          country,
          formattedAddress,
          geoLocation: [latitude, longitude],
        });
      }
    });

    setOptions(newValue ? [newValue, ...options] : options);
  };

  let text = 'No matches';
  if (isSearching) {
    text = 'Searching...';
  } else if (inputValue.length < 8) {
    text = 'Type more than 8 characters';
  }
  return (
    <>
      <Autocomplete
        id='google-map-demo'
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={address ? formatPlaceType(address) : null}
        noOptionsText={text}
        onChange={(event: any, newValue: PlaceType | null) => {
          handlePlaceSelect(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => {
          const inout = {
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                position='start'
                style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 1)' }}
                onClick={toggleCountryPicker}
              >
                <StyledDiv>{countryCodeToFlagEmoji(selectedCountry)}</StyledDiv>
              </InputAdornment>
            ),
          };

          return (
            <TextField
              {...params}
              label='Prefered Location'
              fullWidth
              helperText={helperText}
              error={error}
              InputProps={inout}
            />
          );
        }}
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems='center'>
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component='span'
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant='body2' color='text.secondary'>
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <Dialog onClose={toggleCountryPicker} open={countryPickerOpen}>
        <DialogTitle>Choose a Country</DialogTitle>
        <List>
          {countryList.map((country) => (
            <ListItem
              button
              onClick={() => {
                handleCountryChange(country.code);
                toggleCountryPicker();
              }}
              key={country.code}
            >
              <ListItemText primary={`${countryCodeToFlagEmoji(country.code)} ${country.name}`} />
            </ListItem>
          ))}
        </List>
      </Dialog>
      <Typography
        sx={{ cursor: 'pointer', ml: 'auto', mt: '-1rem', fontSize: '0.6rem' }}
        onClick={toggleManualEntry}
      >
        Enter address manually
      </Typography>
      {(address || manualEntry) && (
        <Box sx={{ mt: 2 }}>
          <TextField
            label='Address Line 1'
            fullWidth
            margin='normal'
            value={address?.addressLine1}
            variant='outlined'
            name='addressLine1'
            onChange={handleAddressChange}
          />
          <TextField
            label='Address Line 2'
            fullWidth
            margin='normal'
            value={address?.addressLine2}
            variant='outlined'
            name='addressLine2'
            onChange={handleAddressChange}
          />
          <TextField
            label='Address Line 3'
            fullWidth
            name='addressLine3'
            margin='normal'
            value={address?.addressLine3}
            variant='outlined'
            onChange={handleAddressChange}
          />
          <TextField
            label='City'
            fullWidth
            name='city'
            margin='normal'
            value={address?.city}
            variant='outlined'
            onChange={handleAddressChange}
          />
          <TextField
            label='County'
            fullWidth
            name='county'
            margin='normal'
            value={address?.county}
            variant='outlined'
            onChange={handleAddressChange}
          />
          <TextField
            label='Postcode'
            fullWidth
            name='postCode'
            margin='normal'
            value={address?.postCode}
            variant='outlined'
            onChange={handleAddressChange}
          />
        </Box>
      )}
    </>
  );
}

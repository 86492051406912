import { Work } from '@mui/icons-material';
import {
  Box,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
  OutlinedInput,
  Theme,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useConfig } from '../../ConfigProvider';
import { Role } from '../../Pages/Registration/RegistrationTypes';
import config from '../../config/config';

const {
  api: { categoriesPath },
  url,
} = config;

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StyledChip = styled(Chip)({
  root: {
    fontSize: '0.5rem',
  },
  label: {
    fontSize: '0.5rem',
  },
});

interface MyComponentProps {
  value: string[];
  onChange: (event: SelectChangeEvent<string[]>, child: React.ReactNode) => void;
  error?: boolean;
  helperText?: string;
}

const MyComponent: React.FC<MyComponentProps> = ({ value, onChange, error, helperText }) => {
  const config = useConfig();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categories, setcategories] = useState<Role[]>([]);

  useEffect(() => {
    async function fetchData() {
      await axios
        .get(`${url}${categoriesPath}`, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Tenant: config.tenantId,
          },
        })
        .then((response) => {
          setcategories(response.data);
        })
        .catch(() => {
          console.log('error');
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
    }

    fetchData();
  }, []);
  return (
    <FormControl variant='outlined' fullWidth>
      <InputLabel>Roles interested</InputLabel>
      <Select
        multiple
        value={value}
        disabled={isLoading}
        onChange={onChange}
        label='Roles interested'
        input={
          <OutlinedInput
            startAdornment={
              <InputAdornment position='start'>
                <Work />
              </InputAdornment>
            }
            label='Roles interested'
            error={!!error}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, fontSize: '0.8rem' }}>
            {selected.map((value) => (
              <StyledChip
                key={value}
                label={value}
                sx={{ fontSize: '0.65rem', fontWeight: '500', height: '1.4rem' }}
              />
            ))}
          </Box>
        )}
      >
        {categories.map(({ name, id }) => (
          <MenuItem key={name} value={id} style={getStyles(id, value, theme)}>
            {name}
          </MenuItem>
        ))}
      </Select>
      {!!error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default MyComponent;

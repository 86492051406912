import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { useApiData } from './ApplicationsProvider';

type User = {
  email: string;
  firstName: string;
  surname: string;
};

type AuthContextType = {
  user: User | null | undefined;
  signOut: () => void;
  setUser: (user: User | null) => void;
  login: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

type AuthProviderProps = {
  children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null | undefined>(null);
  const navigate = useNavigate();
  const { loadData } = useApiData();
  const signOut = () => {
    Cookies.remove('authToken');
    setUser(undefined);
    navigate('/');
  };

  const login = () => {
    const token = Cookies.get('authToken');

    if (token) {
      const decodedToken: User = jwt_decode(token);
      if (decodedToken.email) {
        setUser({
          email: decodedToken.email,
          firstName: decodedToken.firstName,
          surname: decodedToken.surname,
        });
      }
      loadData();
    } else {
      setUser(undefined);
    }
  };

  useEffect(() => {
    const token = Cookies.get('authToken');

    if (token) {
      const decodedToken: User = jwt_decode(token);
      if (decodedToken.email) {
        setUser({
          email: decodedToken.email,
          firstName: decodedToken.firstName,
          surname: decodedToken.surname,
        });
      }
    } else {
      setUser(undefined);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, signOut, login }}>
      {children}
    </AuthContext.Provider>
  );
}

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useConfig } from '../../ConfigProvider';
// Create the keyframes for pulsate animation
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
`;

// Styled component for the image
const PulsatingImage = styled.img`
  width: 50px;
  animation: ${pulse} 1s linear infinite;
  opacity: 0.6;
  border-radius: 10%;
`;

const LoadingSpinner = () => {
  const config = useConfig();

  return (
    <Box display='flex' justifyContent='center' alignItems='center' sx={{ padding: '4rem' }}>
      <PulsatingImage src={config?.theme?.logo} alt='loading' />
    </Box>
  );
};

export default LoadingSpinner;

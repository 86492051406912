import { Box, Divider } from '@mui/material';

interface DividerWithTextProps {
  children?: React.ReactNode;
}
const DividerWithText = ({ children }: DividerWithTextProps) => (
  <Box sx={{ width: '100%', mt: '1rem', mb: '0.5rem', margin: 'auto' }}>
    <Divider
      sx={{
        margin: 'auto',
      }}
      light
      variant='inset'
    >
      {children}
    </Divider>
  </Box>
);

export default DividerWithText;

import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

type ExtendedFile = File & { preview?: string };

interface DropzoneProps {
  onChange: (file: ExtendedFile | null) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ onChange }) => {
  const [file, setFile] = useState<ExtendedFile | null>(null);

  const getDefaultPreview = (fileType: string) => {
    if (fileType === 'application/pdf') {
      return '/pdf.png';
    } else if (fileType.includes('word')) {
      return '/wordDoc.png';
    }
    return '';
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const currentFile = acceptedFiles[0] as ExtendedFile;
      const isDocument = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ].includes(currentFile.type);
      currentFile.preview = isDocument
        ? getDefaultPreview(currentFile.type)
        : URL.createObjectURL(currentFile);
      setFile(currentFile);
      onChange(currentFile);
    },
  });

  useEffect(() => {
    return () => {
      if (
        file &&
        ![
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ].includes(file.type)
      ) {
        URL.revokeObjectURL(file.preview!);
      }
    };
  }, [file]);

  const removeFile = () => {
    setFile(null);
    onChange(null);
  };

  return (
    <Box
      sx={{
        background: 'white',
        width: '100%',
      }}
    >
      <Paper
        elevation={0}
        {...getRootProps({ className: 'dropzone' })}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.1rem',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto',
          border: '0.2rem dashed lightgrey',
          padding: 2,
        }}
      >
        <input {...getInputProps()} />

        {file && (
          <Box
            position='relative'
            sx={{ ...thumbStyles, display: 'flex', flexDirection: 'column' }}
          >
            <IconButton
              size='small'
              onClick={(e) => {
                removeFile();
                e.stopPropagation();
                return false;
              }}
              sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
            <img
              src={file.preview}
              alt={file.name}
              style={imgStyles}
              onLoad={() => {
                URL.revokeObjectURL(file.preview!);
              }}
            />
            <Typography align='center' variant='caption' sx={{ mt: 1, color: 'black' }}>
              {file.name}
            </Typography>
          </Box>
        )}

        {!file && (
          <>
            <IconButton>
              <CloudUploadIcon sx={{ fontSize: '2rem', color: 'grey' }} />
            </IconButton>
            <Typography align='center' variant='body1'>
              Upload CV
            </Typography>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default Dropzone;

const thumbStyles = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  boxSizing: 'border-box',
  overflow: 'hidden',
  position: 'relative',
  padding: 1,
};

const imgStyles = {
  display: 'block',
  maxWidth: '3rem',
  height: '3rem',
  margin: 'auto',
};

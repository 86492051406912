import React from 'react';
import { Box, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { PageType } from '../JobPageTypes';

interface PageChangerProps {
  setPage: (page: PageType) => void;
  page: PageType;
  showHiredState: boolean;
}

const StyledJobDetailsToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  width: '100%',
  color: '#292929',
  borderRadius: '4px',
  backgroundColor: '#DADADA',
  '& .MuiToggleButtonGroup-grouped': {
    margin: 4,
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '4px',
    },
    '&:first-of-type': {
      borderRadius: '4px',
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      color: '#292929',
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)({
  width: '50%',
  textTransform: 'none',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: 'grey', // customize this color
    color: 'white',
  },
  '&:not(:first-of-type)': {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)', // Adjust the color for the border
  },
});

const PageChanger: React.FC<PageChangerProps> = ({ setPage, page, showHiredState }) => {
  const options = [
    { value: 'ACTIONS', label: 'Actions' },
    { value: 'DETAILS', label: 'Details' },
  ];

  if (showHiredState) {
    options.unshift({ value: 'SPLASH', label: 'Welcome' });
  }

  return (
    <Box sx={{ paddingBottom: '1rem' }}>
      <StyledJobDetailsToggleButtonGroup
        value={page}
        exclusive
        onChange={(e, value) => {
          setPage(value as PageType);
        }}
      >
        {options.map((option) => (
          <StyledToggleButton key={option.value} value={option.value}>
            {option.label}
          </StyledToggleButton>
        ))}
      </StyledJobDetailsToggleButtonGroup>
    </Box>
  );
};

export default PageChanger;

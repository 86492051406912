import React from 'react';
import { Box, Button, Typography, styled } from '@mui/material';

type TaskButtonProps = {
  notificationCount: number;
  onButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const NumberCircle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: '#EDA417', // Use your color
  color: 'black',
  fontSize: '14px',
  fontWeight: 'bold',
});

const TaskButton: React.FC<TaskButtonProps> = ({ notificationCount, onButtonClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 1,
        backgroundColor: '#FFDE8B',
        borderRadius: '5px',
        boxShadow: 0,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <NumberCircle>{notificationCount}</NumberCircle>
        <Typography
          variant='body2'
          sx={{ fontWeight: 'bold', margin: 'auto', pl: 1, color: '#B25D2B' }}
        >
          Actions Required!
        </Typography>
      </Box>
      <Button
        variant='contained'
        onClick={onButtonClick}
        sx={{
          textTransform: 'none', // To keep the button text as is
          borderRadius: 1, // Rounded corners for the button
          marginLeft: 1, // Space between badge and button
          backgroundColor: '#0A74D8', // Replace with your actual color
          color: 'white', // Replace with your actual color
          fontWeight: 'bold', // To make the text bold
          boxShadow: 0, // To remove the default box shadow
          fontSize: '1.1rem', // To change the font size
        }}
      >
        View Tasks
      </Button>
    </Box>
  );
};

export default TaskButton;

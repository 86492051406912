import { AppBar, Toolbar, IconButton, Badge, Box, Menu, MenuItem } from '@mui/material';
import React from 'react';
import InitialledCircle from '../InitialledCircle/Index';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/system';
import { useAuth } from '../../AuthProvider';
import { useConfig } from '../../ConfigProvider';

interface StyledColourEdgeProps {
  primaryColour: string;
  secondaryColour: string;
}
const StyledColourEdge = styled('div')`
  width: 100%;
  height: 0.5rem;
  ${({ primaryColour, secondaryColour }: StyledColourEdgeProps) => `
    background-image: linear-gradient(to right, ${primaryColour}, ${secondaryColour});
  `}
`;

const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '4rem',
  width: '6rem',
  [theme.breakpoints.down('sm')]: {
    height: '4rem',
    width: '4rem',
  },
  borderRadius: '0.5rem',
  overflow: 'hidden',
  background: theme.palette.background.default,
  padding: '0.5rem',
}));

const LogoImage = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  borderRadius: '10%',
});

const Header: React.FC = () => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const config = useConfig();

  const { signOut, user } = useAuth();

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleLogoutClick = () => {
    handleMobileMenuClose();
    signOut();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  return (
    <AppBar position='fixed' sx={{ backgroundColor: 'white' }}>
      <Box>
        <Toolbar
          sx={{ height: '100%', display: 'flex', justifyContent: 'space-between', px: '1rem' }}
          disableGutters
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
            <LogoBox>
              <LogoImage src={config?.theme?.logo} alt='Logo' />
            </LogoBox>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton color='inherit' sx={{ padding: 0 }} onClick={handleMenuClick}>
            <Badge color='primary'>
              <InitialledCircle
                firstInitial={user?.firstName && user?.firstName[0]}
                secondInitial={user?.surname && user?.surname[0]}
              />
            </Badge>
          </IconButton>
        </Toolbar>
        <StyledColourEdge
          primaryColour={config?.theme?.muiTheme?.data?.palette?.primary?.main}
          secondaryColour={config?.theme?.muiTheme?.data?.palette?.secondary?.main}
        />
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id='mobile-menu'
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem onClick={handleLogoutClick}>
            <IconButton color='inherit'>
              <Badge color='secondary'>
                <LogoutIcon />
              </Badge>
            </IconButton>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </AppBar>
  );
};

export default Header;

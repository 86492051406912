import { AccountCircle, Email } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
  FormControl,
  FormHelperText,
} from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { matchIsValidTel } from 'mui-tel-input';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useAuth } from '../../AuthProvider';
import { useConfig } from '../../ConfigProvider';
import CentreContainer from '../../SharedComponents/CentreContainer/ExternalPage';
import MyDropzone from '../../SharedComponents/DropZone/Index';
import JobCatagorySelect from '../../SharedComponents/JobCategoriesSelect/Index';
import LoadingSpinner from '../../SharedComponents/LoadingIcon/Index';
import LocationSelect from '../../SharedComponents/LocationSelect/Index';
import PhoneNumber from '../../SharedComponents/PhoneNumber/Index';
import config from '../../config/config';
import { validateEmail } from '../../helperFunctions';
import SuccessMessage from './Components/SuccessMessage';
import { FormErrors, FormValues } from './RegistrationTypes';

const {
  api: { registrationPath },
  url,
} = config;

const RegistrationPage = () => {
  const theme = useTheme();
  const { login, user } = useAuth();
  const config = useConfig();

  const [formValues, setFormValues] = useState<FormValues>({
    firstName: user?.firstName || '',
    surname: user?.surname || '',
    email: user?.email || '',
    contactNumber: '',
    location: null,
    categories: [],
    cv: null,
    termsAndConditions: false,
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: FormEvent) => {
    setIsLoading(true);

    event.preventDefault();
    const errors: FormErrors = {};

    if (!formValues.firstName) errors.firstName = 'First name is required';
    if (!formValues.surname) errors.surname = 'Last name is required';
    if (!formValues.location) errors.location = 'Location is required';
    if (!formValues.termsAndConditions)
      errors.termsAndConditions = 'Please accept the terms and conditions';
    if (formValues.categories.length === 0)
      errors.categories = 'Please select at least one job category';
    if (!matchIsValidTel(formValues.contactNumber))
      errors.contactNumber = 'Contact number is not valid';
    if (!formValues.email) errors.email = 'Email is required';
    else if (!validateEmail(formValues.email)) errors.email = 'Enter a valid email';

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsLoading(false);

      return;
    }

    await axios
      .post(
        `${url}${registrationPath}`,
        {
          firstName: formValues.firstName,
          surname: formValues.surname,
          email: formValues.email?.trim()?.toLowerCase(),
          contactNumber: formValues.contactNumber,
          termsAndCond: true,
          tenantId: config.tenantId,
          preferences: {
            locations: [formValues.location],
            categories: formValues.categories,
          },
          doLogin: true,
        },
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Tenant: config.tenantId,
          },
        },
      )
      .then((response) => {
        Cookies.set('authToken', response.data.response.access_token, { expires: 1 / 24 });
        login();

        setIsSuccess(true);
      })
      .catch((error: AxiosError) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const token = Cookies.get('authToken');
    if (user && user.firstName && token) {
      navigate(`/applications`);
    }
  }, [user]);

  return (
    <CentreContainer hasLogo>
      {isLoading ? (
        <LoadingSpinner />
      ) : isSuccess ? (
        <SuccessMessage />
      ) : (
        <>
          <Typography variant='h5'>Nearly there...</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              width: '-webkit-fill-available',
              marginLeft: '1rem',
              marginRight: '1rem',
              [theme.breakpoints.down('sm')]: {
                marginLeft: '5px',
                marginRight: '5px',
              },
            }}
          >
            <TextField
              name='firstName'
              label='First Name'
              value={formValues.firstName}
              onChange={handleInputChange}
              error={Boolean(formErrors.firstName)}
              helperText={formErrors.firstName}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              name='surname'
              label='Last Name'
              value={formValues.surname}
              onChange={handleInputChange}
              error={Boolean(formErrors.surname)}
              helperText={formErrors.surname}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              name='email'
              label='Email'
              value={formValues.email}
              onChange={handleInputChange}
              error={Boolean(formErrors.email)}
              helperText={formErrors.email}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
            <PhoneNumber
              handleInputChange={(value) => {
                setFormValues({ ...formValues, contactNumber: value });
              }}
              formErrors={formErrors}
              formValues={formValues}
            />
            <JobCatagorySelect
              onChange={(event: SelectChangeEvent<string[]>) => {
                const {
                  target: { value },
                } = event;
                setFormValues({
                  ...formValues,
                  categories: typeof value === 'string' ? [value] : value,
                });
              }}
              value={formValues.categories}
              error={Boolean(formErrors.categories)}
              helperText={formErrors.categories}
            />
            <LocationSelect
              onChange={(value) => {
                if (value === null) {
                  setFormValues({ ...formValues, location: value });
                } else {
                  setFormValues({ ...formValues, location: value });
                }
              }}
              error={Boolean(formErrors.location)}
              helperText={formErrors.location}
            />
            <MyDropzone
              onChange={(value) => {
                setFormValues({ ...formValues, cv: value ? value : null });
              }}
            />
            <FormControl error={Boolean(formErrors.termsAndConditions)}>
              <FormControlLabel
                value='end'
                control={
                  <Checkbox
                    onChange={(event) => {
                      setFormValues({ ...formValues, termsAndConditions: event.target.checked });
                    }}
                  />
                }
                label='I agree to the terms and conditions'
                labelPlacement='end'
              />
              {Boolean(formErrors.termsAndConditions) && (
                <FormHelperText>{formErrors.termsAndConditions}</FormHelperText>
              )}
            </FormControl>
            <Button variant='contained' onClick={handleSubmit} sx={{ mb: '2rem' }}>
              Complete
            </Button>
          </Box>
        </>
      )}
    </CentreContainer>
  );
};

export default RegistrationPage;

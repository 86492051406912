import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function handleLoginError(error: AxiosError): void {
  if (!error.response) {
    toast.error('A network error occurred. Please try again later.', {
      toastId: 'networkError',
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } else if (error.response?.status === 401) {
    toast.error('Unauthorized access. Please check your credentials.', {
      toastId: 'wrongLink',
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } else if (error.response?.status === 423) {
    toast.error('Rate limited. Please try again later.', {
      toastId: 'rateLimitError',
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } else if (error.response?.status === 503) {
    toast.error('The service is temporarily unavailable. Please try again later.', {
      toastId: 'serviceUnavailable',
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } else {
    toast.error('An error occurred. Please try again.');
  }
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function validateEmail(email: string): boolean {
  return emailRegex.test(email);
}

export function daysAgo(inputDate: string) {
  const currentDate = new Date();
  const convertedInputDate = new Date(inputDate);

  // Calculate the difference in milliseconds
  const timeDiff = currentDate.getTime() - convertedInputDate.getTime();

  // Convert milliseconds to days
  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

  // Format the message
  if (daysDiff === 0) {
    return 'Today';
  } else if (daysDiff === 1) {
    return '1 day ago';
  } else {
    return `${daysDiff} days ago`;
  }
}

export function formatDate(newDate: string) {
  const date = new Date(newDate);
  // Helper function to format the month
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return `${date.getDate()} ${months[date.getMonth()]} '${date
    .getFullYear()
    .toString()
    .substring(2)}`;
}

export function errorMessageFinder(error: AxiosError): string {
  if (error.response) {
    switch (error.response.status) {
      case 503:
        return 'The service is temporarily unavailable. Please try again later.';
      case 404:
        return 'The requested resource was not found.';
      case 401:
        return 'You are not authorized to access this resource.';
      default:
        return 'An unexpected error occurred. Please try again.';
    }
  } else {
    return 'Failed to connect to the service. Please check your internet connection and try again.';
  }
}

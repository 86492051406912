import React from 'react';
import { Box, useTheme, Typography, Button, styled, Link } from '@mui/material';
import { useConfig } from '../../../ConfigProvider';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const StyledLink = styled(Link)({
  marginTop: '0.8rem',
  fontSize: '0.8rem',
  color: 'grey',
  cursor: 'pointer',
});

interface SuccessMessageProps {
  emailAddress: string;
  loginAgain: () => void;
}

interface EmailProviderDetails {
  url: string;
  label: string;
  color: string;
}

// Define a type for the email providers object

interface EmailProviderPattern {
  pattern: RegExp;
  details: EmailProviderDetails;
}

const emailProviderPatterns: EmailProviderPattern[] = [
  {
    pattern: /gmail/,
    details: { url: 'https://mail.google.com/', label: 'Go to Gmail', color: '#D93025' },
  },
  {
    pattern: /(hotmail|outlook|live)/,
    details: { url: 'https://outlook.live.com/', label: 'Go to Outlook', color: '#0078D4' },
  },
  {
    pattern: /yahoo/,
    details: { url: 'https://mail.yahoo.com/', label: 'Go to Yahoo', color: '#430297' },
  },
  {
    pattern: /aol/,
    details: { url: 'https://mail.aol.com/', label: 'Go to AOL Mail', color: '#00B2A9' },
  },
  {
    pattern: /icloud/,
    details: { url: 'https://www.icloud.com/mail', label: 'Go to iCloud Mail', color: '#3693F3' },
  },
  {
    pattern: /e4s\.co$/,
    details: {
      url: 'https://eu1.myprofessionalmail.com/',
      label: 'Go to WebMail',
      color: '#3693F3',
    },
  },
];

const SuccessMessage = ({ emailAddress, loginAgain }: SuccessMessageProps) => {
  const theme = useTheme();
  const config = useConfig();

  const getEmailProviderDetails = (email: string): EmailProviderDetails | null => {
    const domain = email.split('@')[1]?.toLowerCase();
    if (!domain) return null;

    const matchedProvider = emailProviderPatterns.find((providerPattern) =>
      providerPattern.pattern.test(domain),
    );

    return matchedProvider ? matchedProvider.details : null;
  };

  const emailProviderDetails = getEmailProviderDetails(emailAddress);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        width: '-webkit-fill-available',
        marginLeft: '3rem',
        marginRight: '3rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          marginLeft: '5px',
          marginRight: '5px',
        },
      }}
    >
      <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
        {config.tenantName}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', mb: '1rem' }}>
        <Typography
          variant='body1'
          sx={{
            textAlign: 'center',
            color: '#2D2D2D',
          }}
        >
          You can now close this window.
        </Typography>
        <Typography
          variant='body1'
          sx={{
            textAlign: 'center',
            color: '#2D2D2D',
          }}
        >
          Please check your email for the magic link!
        </Typography>
        {emailProviderDetails && (
          <Button
            variant='contained'
            startIcon={<MailOutlineIcon />}
            sx={{
              backgroundColor: emailProviderDetails.color,
              ':hover': { backgroundColor: theme.palette.grey[700] },
              width: 'fit-content',
              margin: 'auto',
              mt: '1rem',
              height: '3rem',
              borderRadius: '5px',
              padding: '0 1.5rem',
            }}
            onClick={() => (window.location.href = emailProviderDetails.url)}
          >
            {emailProviderDetails.label}
          </Button>
        )}
        <StyledLink onClick={loginAgain}>Back to Login</StyledLink>
      </Box>
    </Box>
  );
};

export default SuccessMessage;

// SettingsPage.tsx

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../SharedComponents/LoadingIcon/Index';
import PageContainer from '../../SharedComponents/PageContainer/Index';
import config from '../../config/config';
import SettingsSection from './Components/ProfileSection';
import { section, RespType } from './ProfileTypes';
import { useConfig } from '../../ConfigProvider';

const {
  api: { profilePath },
  url,
} = config;

const SettingsPage: React.FC = () => {
  const [editingSection, setEditingSection] = useState('');
  const navigate = useNavigate();
  const [sections, setSections] = useState<section[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();

  useEffect(() => {
    async function fetchData() {
      await axios
        .get(`${url}${profilePath}`, {
          headers: {
            Authorization: `Bearer ${Cookies.get('authToken')}`,
          },
        })
        .then(({ data }: RespType) => {
          const newJobs: section[] = [
            {
              title: 'Name',
              fields: [
                { type: 'text', value: data?.personalDetails?.givenName, label: 'First Name' },
                { type: 'text', value: data?.personalDetails?.familyName, label: 'Surname' },
              ],
              displayValue: `${data?.personalDetails?.givenName} ${data?.personalDetails?.familyName}`,
            },

            {
              title: 'Comunication',
              fields: [
                { type: 'text', value: data?.communication?.emailAddress, label: 'Email' },
                {
                  type: 'text',
                  value: data?.communication?.phone?.mobileTelephone,
                  label: 'Mobile',
                },
              ],
              displayValue: [
                data?.communication?.emailAddress,
                data?.communication?.phone?.mobileTelephone,
              ],
            },
            {
              title: 'Preferred Job Category',
              fields: [
                {
                  type: 'jobCategoriesSelect',
                  value: data?.employmentPreferences?.positionPreference?.categories,
                  label: 'Roles',
                },
              ],
              displayValue: data?.employmentPreferences?.positionPreference?.categories,
            },
            {
              title: 'Prefered Location',
              fields: [
                {
                  type: 'location',
                  value: data?.employmentPreferences?.positionPreference?.locations[0],
                  label: 'Location',
                },
              ],
              displayValue:
                data?.employmentPreferences?.positionPreference?.locations[0]?.formattedAddress,
            },
          ];

          setSections(newJobs);
        })
        .catch((error: AxiosError) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    fetchData();
  }, []);

  const handleEdit = (title: string, editing: boolean) => {
    setEditingSection(editing ? title : '');
  };

  const handleUpdate = async (newSettings: any) => {
    await axios
      .put(
        `${url}${profilePath}`,
        {
          employmentPreferences: {
            positionPreference: {
              categories: ['Test'],
              locations: [
                {
                  city: 'London',
                  geoLocation: {
                    type: 'Point',
                    coordinates: [[0, 0]],
                  },
                  formattedAddress: 'Nyhavn 63C, Copenhagen, Denmark',
                },
              ],
            },
          },
          communication: {
            emailAddress: 'AP@e4s.com',
            phone: {
              mobileTelephone: '+44 07943 526240',
            },
            postalAddress: {
              country: '',
            },
          },
          personalDetails: {
            familyName: 'Parker',
            givenName: 'Alan',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('authToken')}`,
            Tenant: config.tenantId,
          },
        },
      )
      .then((response) => {
        const {
          employmentPreferences: {
            positionPreferences: { categories },
          },
          communication: {
            emailAddress,
            phone: { mobileTelephone },
            // postalAddress: { country },
          },
          personalDetails: { familyName, givenName },
        } = response.data;
      });
    setSections(newSettings);
  };

  return (
    <PageContainer>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ mt: 3 }}>
          <IconButton sx={{ ml: -1.5, pb: 2 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon />
            <Typography sx={{ fontSize: '1rem', ml: '0.5rem' }}>Back</Typography>
          </IconButton>
          <Typography sx={{ fontSize: '1.6rem', lineHeight: '3rem', color: 'rgba(0, 0, 0, 0.8)' }}>
            Personal details
          </Typography>

          <Typography sx={{ fontSize: '1rem', color: 'gray' }}>
            Update your information and find out how it's used.
          </Typography>
          {sections.map((section, index) => (
            <SettingsSection
              key={index}
              title={section.title}
              fields={section.fields}
              displayValue={section.displayValue}
              onEdit={handleEdit}
              disabled={editingSection !== '' && editingSection !== section.title}
              onSave={handleUpdate}
            />
          ))}
        </Box>
      )}
    </PageContainer>
  );
};

export default SettingsPage;

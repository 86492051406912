import React, { useEffect, useState, createContext, useContext, ReactNode } from 'react';
import axios from 'axios';
import { ThemeConfig } from './ThemeProviderTypes';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import config from './config/config';

const {
  api: { configPath },
  url,
} = config;

interface ConfigData {
  host: string;
  theme: {
    logo: string;
    background: string;
    muiTheme: ThemeConfig;
  };
  tenantId: string;
  tenantName: string;
  title: string;
  metaDescription: string;
  welcomeMediaUrl: string;
  privacyPolicyURL: string;
  termsOfUseURL: string;
  welcomeMessage: string;
}

const ConfigContext = createContext<ConfigData | undefined>(undefined);

export const useConfig = (): ConfigData => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

interface ConfigProviderProps {
  children: ReactNode;
}

const updateFavicon = (iconUrl: string): void => {
  let link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
  if (!link) {
    link = document.createElement('link');
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = iconUrl;
};

const updateMetaTags = (title: string, description: string): void => {
  document.title = title;

  let meta: HTMLMetaElement | null = document.querySelector(`meta[name="description"]`);
  if (!meta) {
    meta = document.createElement('meta');
    meta.setAttribute('name', 'description');
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
  meta.setAttribute('content', description);
};

const componentsSettings = {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '10px', // Apply border radius to all dialog modals
        },
      },
    },
  },
};
const baseConfig = {
  host: '',
  tenantName: 'TalentHub',
  theme: {
    logo: '/default_logo.png',
    favicon: '/default_logo.png',
    background: '',
    muiTheme: {
      data: {
        typography: {
          fontFamily: 'Rubik, Helvetica, Arial, sans-serif',
        },
        palette: {
          primary: {
            main: '#00579A',
          },
          secondary: {
            main: '#4FE1C2',
          },
        },
        ...componentsSettings,
      },
    },
  },
  tenantId: '9b03dd2d-5333-471b-b623-c7cdc23b4768',
  title: 'TalentHub',
  metaDescription: 'Discover exciting opportunities',
  privacyPolicyURL: 'https://www.example.com/privacy-policy',
  termsOfUseURL: 'https://www.example.com/terms-of-use',
  welcomeMessage: 'Welcome to <tenant name>',
  welcomeMediaUrl: '',
};

const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<ConfigData | null>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const currentDomain = window.location.host;
    axios
      .get(`${url}${configPath}?host=${currentDomain}`)
      .then((response) => {
        setConfig({
          ...response.data,
          theme: {
            ...response.data.theme,
            muiTheme: {
              data: {
                ...response.data.theme.muiTheme.data,
                ...componentsSettings,
              },
            },
          },
        });

        updateFavicon(response.data.theme.favicon);
        updateMetaTags(response.data.title, response.data.metaDescription);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setConfig(baseConfig);
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ display: 'flex', height: '100vh', width: '100vw' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!config) {
    return <Box>Failed to fetch configuration data</Box>;
  }

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export default ConfigProvider;

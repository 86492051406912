import { Typography, Link, styled } from '@mui/material';
import { useConfig } from '../../../ConfigProvider';

// Styled component for the container
const TypographyBox = styled(Typography)`
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  color: rgba(110, 110, 110);
  font-size: 0.8rem;
`;

// Styled component for the links
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledSeparator = styled(Typography)`
  margin: 0 0.5rem; // Adjust based on your layout
  color: #999; // Example color, adjust as needed
  font-size: 1rem;
`;

const PrivacyLinks: React.FC = () => {
  const config = useConfig();

  return (
    <TypographyBox>
      {config?.privacyPolicyURL && (
        <>
          <StyledLink href={config?.privacyPolicyURL} target='_blank' rel='noopener noreferrer'>
            Privacy Policy
          </StyledLink>
          {config?.termsOfUseURL && <StyledSeparator>•</StyledSeparator>}
        </>
      )}
      {config?.termsOfUseURL && (
        <StyledLink href={config?.termsOfUseURL} target='_blank' rel='noopener noreferrer'>
          Terms of Use
        </StyledLink>
      )}
    </TypographyBox>
  );
};

export default PrivacyLinks;

import { Box, Button, ListItem, styled } from '@mui/material';
import Cookies from 'js-cookie';
import WhatsNextIcon from '../../../Icons/WhatsNextIcon';

// Define the styles for the custom list item
const CustomListItem = styled(ListItem)(() => ({
  borderRadius: 8,
  marginBottom: '1.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: 'none',
  padding: '1rem',
  position: 'relative',

  '&::after': {
    content: '" "',
    position: 'absolute',
    bottom: '-45px',
    borderLeft: '2px solid green',
    height: 100,
    left: 31,
    zIndex: -1,
  },

  '&.todo::after': {
    content: '" "',
    position: 'absolute',
    bottom: '-45px',
    borderLeft: '2px dotted #FFDE8B',
    height: 100,
    left: 31,
    zIndex: -1,
  },

  '&.urgent::after': {
    content: '" "',
    position: 'absolute',
    bottom: '-45px',
    borderLeft: '2px dotted #FFACA1',
    height: 100,
    left: 31,
    zIndex: -1,
  },

  '&.viewed svg g': {
    stroke: '#827c7c !important',
  },
}));

const StartButton = styled(Button)(({ theme }) => ({
  background: '#0A74D8', // Amber color
  '&:hover': {
    background: '#FFCA28', // A bit darker on hover
  },
  color: 'white',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '1.1rem',
}));

interface CustomActionItemProps {
  url: string;
  handleOpenModal: (iframeSrc: string, title: string, id: string, isModal?: boolean) => void;
  message?: string;
  jobId?: string;
}

function CustomActionItem({ message, url, handleOpenModal, jobId }: CustomActionItemProps) {
  let hasSeenAction = false;
  const viewedActionUrl = Cookies.get(`viewedActionUrl-${jobId}`);

  if (viewedActionUrl) {
    hasSeenAction = viewedActionUrl === url;
  }

  return (
    <CustomListItem
      sx={{
        backgroundColor: hasSeenAction ? '#f5c28f' : '#FF7F00',
      }}
      className={hasSeenAction ? 'viewed' : 'whatsNext'}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <WhatsNextIcon />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            color: hasSeenAction ? '#827c7c' : 'white',
          }}
        >
          <span>{message}</span>
        </Box>
      </Box>
      <StartButton
        variant='contained'
        onClick={() => {
          handleOpenModal(url || '', message || '', '', true);
          Cookies.set(`viewedActionUrl-${jobId}`, url, { expires: 365 });
        }}
      >
        {hasSeenAction ? 'View again' : 'Open'}
      </StartButton>
    </CustomListItem>
  );
}

export default CustomActionItem;

import baseConfig from '../../src/config/baseConfig';

const config = {
  ...baseConfig,
  version: 0,
  env: 'prod',
  url: 'https://ats-api-dev.talent-funnel.com',
};

export default config;

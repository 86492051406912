import { SVGProps } from 'react';
import { JSX } from 'react/jsx-runtime';

const SVGComponent = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='32' height='32' rx='16' fill='#C63B0F' />
    <path
      d='M16.0005 13.5001V16.6251M8.24784 19.4381C7.52668 20.6881 8.42883 22.2501 9.87194 22.2501H22.1291C23.5723 22.2501 24.4744 20.6881 23.7532 19.4381L17.6246 8.81516C16.9031 7.56446 15.098 7.56446 14.3764 8.81516L8.24784 19.4381ZM16.0005 19.1251H16.0068V19.1313H16.0005V19.1251Z'
      stroke='#FAFBFF'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SVGComponent;

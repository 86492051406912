import { Button, styled } from '@mui/material';
import { formatDate } from '../../../helperFunctions';

const StartButton = styled(Button)(({ theme }) => ({
  background: '#0A74D8', // Amber color
  '&:hover': {
    background: '#FFCA28', // A bit darker on hover
  },
  color: 'white',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '1.1rem',
}));

interface ListActionProps {
  state: string;
  iframeSrc?: string;
  title: string;
  completedDate: string;
  id: string;
  handleOpenModal: (iframeSrc: string, title: string, id: string, isModal?: boolean) => void;
  message?: string;
}

function ListAction({
  state,
  iframeSrc,
  title,
  id,
  completedDate,
  handleOpenModal,
  message,
}: ListActionProps) {
  if (!iframeSrc) return;

  if (state === 'done') {
    return (
      <span style={{ fontSize: '0.8rem', color: '#616161' }}>
        Completed: {completedDate && formatDate(completedDate)}
      </span>
    );
  }

  if (message) {
    return <span style={{ fontSize: '0.8rem', color: '#616161' }}>{message}</span>;
  }

  return (
    <StartButton
      variant='contained'
      onClick={() => {
        handleOpenModal(iframeSrc, title, id);
      }}
    >
      Start!
    </StartButton>
  );
}

export default ListAction;
